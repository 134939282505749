import React from "react"
import Header from '../components/matrix-page/Header'
import MatrixLookupUi from '../components/matrix-page/MatrixLookupUi'

const MatrixPage = () => {

    return (
        <div className="matrixPage">
            <Header />
            <MatrixLookupUi />
        </div>
    )
}
export default MatrixPage