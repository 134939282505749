import React from 'react'
import { withRouter } from 'react-router-dom'
import logo from '../../assets/compare.png'

const MatrixButton = (props) => {

    const routeToComparePage = () => {
        let selectedRows = props.selectedRows
        if (selectedRows.length > 2) {
            alert("Please only select 2 rows.")
        }
        else if (selectedRows.length < 2) {
            alert("Please select 2 rows.")
        }
        else {
            let codes1 = selectedRows[0].Compare
            let codes2 = selectedRows[1].Compare
            let pathname = "/matrix/compare/codes1=" + codes1 + "&codes2=" + codes2 + (props.xeroxDeviceUrl !== undefined ? "&xeroxDeviceUrl=" + props.xeroxDeviceUrl : "")
            window.open(pathname, "_blank");
        }
    }

    return (
        <div className="compareButtonDiv">
            <button className="compareButton" onClick={routeToComparePage} style={{left: '0', marginTop: '0', border: '1px solid #07243d', width: '125px'}}>
                <img className="image" src={logo} alt="compare button logo - https://icons8.com/icons/set/compare--white" style={{marginRight: '10px', marginBottom: '2px'}}/>
                Compare
            </button> 
        </div>
    )
}

export default withRouter(MatrixButton)