import React from "react"
import '../App.scss'
import InputUI from '../components/input-page/InputUI'
import Details from '../components/input-page/Details'
import { useAuth0 } from '@auth0/auth0-react'
import { getBackendPath } from "../HelperFunctions";

// First page of website, acts as the first UI the user will interact with to enter their input
const HomePage = () => {
    const { user } = useAuth0();
    const backendPath = getBackendPath()

    // POST request to check if authenticated user is in backend
    try {
        const requestData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(user)
        };
        fetch(backendPath + '/backend/venv/database/admin/userCheck', requestData)
            .then(response => response.json())
            .then(data => {
                // reloading page for user since it's their first visit.
                if (data === true) {
                    window.location.reload()
                }
            })
    } catch (error) {
        console.log(error);
    }

    return (
        <div className="homePage">
            <Details />
            <InputUI />
        </div>
    )
}
export default HomePage