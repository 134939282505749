import React from "react"
import Header from '../components/matrix-page/Header'
import NeighbourUi from '../components/matrix-page/neighbour/NeighbourUi'
import '../styles/NeighbourPage.scss';

const NeighbourPage = () => {

    return (
        <div className="neighbourPage">
            <Header />
            <NeighbourUi />
        </div>
    )
}
export default NeighbourPage