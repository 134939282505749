import React from "react";
import '../styles/ResultPage.scss';
import { withRouter } from "react-router-dom"
import logo from '../assets/AUTONOC-logo.png';
import XeroxHomeButton from "../components/xerox/XeroxHomeButton";
import MatrixButton from "../components/matrix-page/MatrixButton";

// thank you page, once the user selects one of the resulting codes it lets them know the backend is done recording their submission
const ThankYou = (props) => {

    return (
        <div className="thankyouPage">
            <div className="logoDiv">
                <img className= "logo" src={logo} alt="sample logo for ENENOC" />
            </div>
            <h1>Thank you!</h1>
            <p>Your submission was successful.</p>

            {/* (special case) users on Xerox devices need a back button due to no URL on MFDs */}
            {props.history.location.state != null && props.history.location.state.xeroxDeviceUrl !== undefined && props.history.location.state.xeroxDeviceUrl !== "" ? 
                <div id="xeroxButtonContainer">
                    <br></br>
                    <XeroxHomeButton overwriteCssPosition={true}/>
                    <div style={{color:"white"}}>Click the home button to be taken back to the Xerox device.</div>
                </div>
                : 
                <></>
            }

            {/* {props.history.location.state != null && props.history.location.state.code !== undefined ? */}
                <div id="matrixButtonContainer">
                    <MatrixButton codes={props.history.location.state.autonocResults} xeroxDeviceUrl={props.history.location.state.xeroxDeviceUrl}/>
                    <div style={{color:"white"}}>Click the matrix button to check out our Matrix Lookup functionality.</div>
                </div>
            {/* } */}
        </div>
    )
}
export default withRouter(ThankYou)