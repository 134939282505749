import React, { useState } from 'react'
import '../../../styles/AdminPage.scss';
import logo from "../../../assets/delete.png"
import { useAuth0 } from '@auth0/auth0-react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

// vars
var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else 
    backendPath = process.env.REACT_APP_BACKEND_PATH

const DeleteButton = () => {
    // state
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [database, setDatabase] = useState('')

    // getting auth0 user to send as body for POST
    const { user } = useAuth0()

    // handle closing/showing
    const handleShowModal1 = () => setShowModal1(true);
    const handleShowModal2 = () => setShowModal2(true);
    const handleShowModal3 = () => setShowModal3(true);
    const handleShowModal4 = () => setShowModal4(true);
    const handleCloseModal1 = () => setShowModal1(false);
    const handleCloseModal2 = () => setShowModal2(false);
    const handleCloseModal3 = () => setShowModal3(false);
    const handleCloseModal4 = () => setShowModal4(false);

    // handle user input for database and showing next modal
    const handleDatabase = () => {

        // set database from 
        setDatabase(document.getElementById('textbox_database').value)

        // hide current modal
        handleCloseModal1()

        // show next modal
        handleShowModal2()
    }

    // handle sending the database the user wants to delete to the backend
    const handleDeletion = () => {

        // hide popup
        handleCloseModal2()

        // create body
        let body = {
            user: user,
            database: database
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + '/backend/venv/database/admin/delete', requestData)
                .then(response => response.json())
                .then(data => {

                    // let user know it was cancelled
                    if (data === 'cancelled') {
                        handleShowModal3()
                    }
                    // let user know it was successful
                    else {
                        handleShowModal4()
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="deleteDiv">
                <button className="deletedelete" onClick={handleShowModal1}>
                    <img className="deleteLogo" src={logo} alt="delete logo" />
                    Delete
                </button>
            </div>

            {/* first modal popup asking user to enter a db name */}
            <Modal
                show={showModal1}
                onHide={handleCloseModal1}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you wish to delete a database?
                    <br></br>
                    If so, then proceed to type in the text field below the exact name of the database you wish to delete.
                    <br></br>
                    Keep in mind you can only delete databases that you have uploaded yourself (this applies to other admin accounts as well).
                    <br></br>
                    <br></br>
                    Example: <b>example_database.csv</b>
                    <input
                        id='textbox_database'
                        name='textbox_database'
                        type='text'
                        maxLength='100'
                        style={{ width: '465px' }}
                    >
                    </input>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal1} style={{ height: 'auto' }}>Cancel</Button>
                    <Button variant="danger" onClick={handleDatabase} style={{ height: 'auto' }}>Continue</Button>
                </Modal.Footer>
            </Modal>

            {/* second modal popup double checking with user before we submit to backend */}
            <Modal
                show={showModal2}
                onHide={handleCloseModal2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This action cannot be undone, deleting this database will permanently remove it from the server.
                    <br></br>
                    Do you still wish to proceed?
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal2} style={{ height: 'auto' }}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeletion} style={{ height: 'auto' }}>Delete</Button>
                </Modal.Footer>
            </Modal>

            {/* error modal popups incase something goes wrong */}
            <Modal
                show={showModal3}
                onHide={handleCloseModal3}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Error, something went wrong...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The server was unable to process your request to delete "{database}".
                    <br></br>
                    <br></br>
                    Possible reasons why this failed:
                    <ul>
                        <li>Incorrect database entry</li>
                        <li>Does not have permission to delete this database</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal3} style={{ height: 'auto' }}>Ok</Button>
                </Modal.Footer>
            </Modal>

            {/* modal popup to tell user it was complete */}
            <Modal
                show={showModal4}
                onHide={handleCloseModal4}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Complete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deletion of "{database}" was successful.
                    <br/><br/>
                    {/* <p style={{fontSize:"14px"}}>*Refresh to see database inserted/removed from list.</p> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {handleCloseModal4(); window.location.reload();}} style={{ height: 'auto' }}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DeleteButton