import React from "react";
import { withRouter, useParams } from "react-router-dom"
import '../../styles/MatrixPage.scss';
import searchIcon from '../../assets/magnifier-glass.png'
import Filter from "bad-words"
import { localhostCheck, checkUrlForXerox } from "../../HelperFunctions"
import RenderMatrixTable from './RenderMatrixTable'
import CompareButton from "./CompareButton";

var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else
    backendPath = process.env.REACT_APP_BACKEND_PATH

backendPath = backendPath + '/backend/venv/matrix/getMatrixLookupData'

class MatrixLookup extends React.Component {

    constructor(props) {
        super(props);

        let jobTitle = this.props.match.params.jobTitle
        if (jobTitle != undefined) {
            jobTitle = jobTitle
        }

        let codes = this.props.match.params.codes
        if (codes != undefined) {
            codes = codes.split(',')
        }

        this.state = {
            jobTitle: jobTitle,
            codes: codes,
            matrixData: '',
            searchByAutonocIsChecked: localhostCheck() ? false : true,
            searchByKeywordsIsChecked: localhostCheck() ? true : false,
            loadingOverlayActive: false,
            selectedRows: [],
            xeroxDeviceUrl: this.props.match.params.xeroxDeviceUrl,
        }
    }

    componentDidMount = () => {
        if (this.state.jobTitle != undefined) {
            this.handleJobTitleSubmit()
        }
        else if (this.state.codes != undefined) {
            this.handleCodesSubmit()
        }
    }

    // handles the change to state variable when input is entered
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleCheckboxChange = (event) => {
        if (event.target.name == "searchByAutonoc") {
            this.setState({ 
                searchByAutonocIsChecked : true,
                searchByKeywordsIsChecked: false
            })
        }
        else {
            this.setState({ 
                searchByAutonocIsChecked : false,
                searchByKeywordsIsChecked: true
            })
        }
    }

    handleJobTitleSubmit = async () => {

        this.setState({
            loadingOverlayActive: true,
        }, () => {})

        if (this.state.searchByAutonocIsChecked) {
            setTimeout(function() { alert("Searching with AUTONOC, this could take ~20-30 seconds. Please close this alert to continue."); }, 1);
        }

        // must be a non-empty entry, checking by JS incase built in HTML5 validation isn't working 
        if (this.state.jobTitle === '') {
            alert("Try again! The job title field is mandatory, please enter your job title!");
            return;
        }

        // script to validate input, exit if bad. 
        const inputIsGood = this.jobTitleValidation(this.state.jobTitle);
        if (inputIsGood === false) {
            document.getElementById("jobTitle").value = "";
            this.state.jobTitle = "";
            return;
        }

        // creating body for POST
        let body = {
            jobTitle: this.state.jobTitle,
            searchMode: (this.state.searchByAutonocIsChecked == true) ? "searchByAutonoc" : "searchByKeywords"
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath, requestData)
                .then(res => res.json())
                .then(data => {
                    this.setState({ 
                        matrixData: (data == "empty") ? [] : data,
                    },
                        () => {
                            this.setState({
                                loadingOverlayActive: false,
                            }, () => {
                                window.history.replaceState(null, "matrix", "/matrix/jobTitle=" + this.state.jobTitle)
                            })
                        });
                });

        } catch (error) {
            console.log(error);
        }

    }

    // very specific scenerio, only occurs if user vists from AUTONOC thank you page.
    handleCodesSubmit = () => {
        this.setState({
            loadingOverlayActive: true,
        }, () => {})

        if (this.state.searchByAutonocIsChecked) {
            setTimeout(function() { alert("Searching with AUTONOC, this could take ~20-30 seconds. Please close this alert to continue."); }, 1);
        }

        // creating body for POST
        let body = {
            codes: this.state.codes,
            searchMode: null
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath, requestData)
                .then(res => res.json())
                .then(data => {
                    this.setState({ 
                        matrixData: (data == "empty") ? [] : data,
                    },
                        () => {
                            document.getElementById("spinnerDiv").style.display = "none";
                            this.setState({
                                loadingOverlayActive: false,
                            }, () => {
                                window.history.replaceState(null, "matrix", "/matrix/codes=" + this.state.codes)
                            })
                        });
                });

        } catch (error) {
            console.log(error);
        }
    }

    // validate input before passing through 
    jobTitleValidation = (jobTitle) => {
        var unwantedChars = "!@#$%^&*()+=[]\\;/{}|:<>?~_";

        // checks if input contains a bad word
        var filter = new Filter();
        if (filter.isProfane(jobTitle) === true) {
            alert("Try again! Your input contains a bad word, please enter something appropriate...");
            return false;
        }

        // scan through each char of input string
        for (var i = 0; i < jobTitle.length; i++) {
            const cur = (jobTitle).charAt(i);
            if (unwantedChars.indexOf(cur) !== -1) {
                alert("Try again! Don't use any special characters besides the following: . , - ' \"")
                return false
            }
        }

        return true;
    }

    handleSelectionCallback = (callbackData) => {
        if (callbackData.mode == 'all') {
            this.setState({selectedRows: (callbackData.isSelect) ? callbackData.rows : []})
        }
        else {
            let rows = this.state.selectedRows
            let selectedRow = callbackData.rowData

            if (callbackData.isSelect == false) {
                rows = rows.filter(item => item !== selectedRow)
            }
            else {
                rows.push(selectedRow)
            }

            this.setState({selectedRows: rows})
        }
    }

    render() {

        return (

            <>
                <div className="MatrixJobLookup">

                    <h1 className="matrixLookupHeader"><strong>Job Transition Matrix: Lookup</strong></h1>

                    {/* UI form for user input */ }
                    {/* <form className="inputForm" onSubmit={this.handleSubmit}> */}
                    <div className="inputForm">
                        
                        {/* get the users job title (mandatory) */}
                        <label>Enter your job title:<br></br></label>
                        
                        <div className="inputAndButtonDiv">
                            <div className="inputFieldDiv">
                                <img src={searchIcon} alt="search logo acquired from uxwing.com w/ no attribution or credit acquired" />
                                <input
                                    id="jobTitle"
                                    type="text"
                                    value={this.state.jobTitle}
                                    name="jobTitle"
                                    onChange={this.handleChange}
                                    placeholder="Example: Electrical Engineer"
                                    spellCheck="value"
                                    maxLength="75"
                                    required
                                />
                            </div>

                            <div className="buttonDiv">
                                <button onClick={this.handleJobTitleSubmit} id="submit" className="submitButton"><span>Submit</span></button>
                            </div>
                        </div>

                        <div className="checkboxDiv">
                            Search Criteria:&nbsp;&nbsp;
                            <input 
                                type="checkbox" 
                                id="searchByAutonoc"
                                name="searchByAutonoc"
                                value = {this.state.searchByAutonocIsChecked}
                                checked={this.state.searchByAutonocIsChecked}
                                onChange={this.handleCheckboxChange}
                            />
                            &nbsp;Autonoc&nbsp;&nbsp;
                            
                            <input 
                                type="checkbox" 
                                id="searchByKeywords"
                                name="searchByKeywords"
                                value = {this.state.searchByKeywordsIsChecked}
                                checked={this.state.searchByKeywordsIsChecked}
                                onChange={this.handleCheckboxChange}
                            />
                            &nbsp;Keywords
                        </div>

                    </div>

                {this.state.matrixData !== '' ? 
                    <>
                        <div className="utilityDiv">
                            <CompareButton selectedRows={this.state.selectedRows} xeroxDeviceUrl={this.state.xeroxDeviceUrl}/>
                        </div>

                        <RenderMatrixTable 
                            data={(typeof this.state.matrixData === "undefined") ? 'no_data' : this.state.matrixData} 
                            loading={this.state.loadingOverlayActive} 
                            selectionCallback={this.handleSelectionCallback}
                        />
                    </>
                    :
                    <></>
                }

                </div>
            </>
        );
    }
}
export default withRouter(MatrixLookup);