import React from 'react'
import '../../../styles/AdminPage.scss';
import logo from "../../../assets/refresh.png"

class RefreshButton extends React.Component {
    // refresh the current page
    refreshPage = () => {
        window.location.reload();
    }

    // return components for refresh button
    render() {
        return (
            <div className="refreshDiv">
                <button className="refresh" onClick={this.refreshPage}>
                    <img className="refreshLogo" src={logo} alt="refresh logo" />
                    Refresh
                </button>
            </div>
        )
    }
}
export default RefreshButton;
