import React from 'react'
import '../../../styles/AdminPage.scss';
import logo from "../../../assets/export.png"
import { useAuth0 } from '@auth0/auth0-react'

// vars
var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else
    backendPath = process.env.REACT_APP_BACKEND_PATH

// Button that exports an HTML table to a CSV file and downloads it.
const ExportButton = (data) => {

    const { user } = useAuth0();

    // data isn't fully rendered yet
    if (data.tableData === '') {
        return (
            <div className="exportDiv">
                <button id="export" onClick={() => alert("Please wait until the data is loaded.")}>
                    <img className="exportLogo" src={logo} alt="export logo" />
                    Export
                </button>
            </div>
        )
    }
    else {
        // creates new tab to start download
        const onClickForExport = () => {
            window.open(backendPath + '/backend/venv/database/admin/download/' + data.filename + '/' + user.email);
        }

        // render components
        return (
            <div className="exportDiv">
                <button id="export" onClick={onClickForExport}>
                    <img className="exportLogo" src={logo} alt="export logo" />
                    Export
                </button>
            </div>
        )
    }
}

export default ExportButton


// converts JSON data to a CSV file
const JSONToCSV = (arrayData, filename) => {

    // vars
    var csvData = "";
    var headers = "";
    var arrData = arrayData;
    var i, index = 0

    // remove dummy key from each dict object in list 
    var key = 'uniqueKey';
    for (i = 0; i < arrData.length; i++) {
        delete arrData[i][key];
    }

    // loop to create column headers
    for (index in arrData[0]) {
        headers += index.toUpperCase() + ",";
    }
    headers = headers.slice(0, -1);

    // append the header row with a line break
    csvData += headers + "\r\n";

    // grab data for each row
    for (i = 0; i < arrData.length; i++) {
        var row = "";

        // apply row data in correct column position and seperate with commas
        for (index in arrData[i]) {
            if (arrData[i][index] === null)
                row += ',';
            else
                row += '"' + arrData[i][index] + '",';
        }
        row.slice(0, row.length - 1);

        //add a line break after each row
        csvData += row + "\r\n";
    }

    // check data before saving
    if (csvData === "") {
        alert("Database was empty or broken, unable to export...");
        return;
    }

    // remove whitespaces in filenames if present for better presentation
    filename = filename.replace(/ /g, "_");

    // set file format to CSV
    // var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var uri = "data:text/csv;charset=utf-8," + encodeURI(csvData);

    // generate a temp <a /> tag and hide it during download process
    var link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    // link.download = filename + ".csv";
    link.download = filename;

    // append anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};