import React, { useState } from 'react'
import Popup from '../../custom/Popup'
import ReactDOMServer from 'react-dom/server'
import { formatAttribute, formatScore } from "../../../HelperFunctions"

const AllAttAndValButton = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const formatData = () => {
        let attAndValList = props.data
        let res = "<div>"
        for (let att in attAndValList) {
            let value = attAndValList[att]
            if (value.includes(".0")) {
                value = value.substring(0, value.indexOf(".0"))
            }
            res += formatAttribute(att) + " = " + formatScore((value.substring(1, 3) == "ch" ? "CH" : "O*NET"), value) + "<br>"
        }
        res += "</div>"
        return res
    }

    return (
        <div>
            <div className="allAttAndValButtonDiv">
                <button className="allAttAndValButton" onClick={togglePopup}>
                    Show All
                </button>
            </div>
            <div></div>
            {isOpen && 
                <Popup
                    content={
                        <>
                            <b>All Attributes and Values</b>
                            <hr/>
                            <div dangerouslySetInnerHTML={{ __html: formatData() }}></div>
                        </>
                    }
                    handleClose={togglePopup}
                />
            }
        </div>
    )
}
export default AllAttAndValButton