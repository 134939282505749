import React from 'react'
import '../../../styles/AdminPage.scss';

// grab backend link
var backendLink = ''
//if (window.location.protocol === 'https:')
backendLink = process.env.REACT_APP_BACKEND_PATH_SECURE + "/admin"
//else 
//backendLink = process.env.REACT_APP_BACKEND_PATH + "/admin"

// button routes the current user to newest admin page
const AdminButton = () => {

    const handleClick = () => {
        window.location.href = backendLink
    }

    return (
        <div className="adminButtonDiv">
            <button className="adminButton" onClick={handleClick}>
                Admin
            </button>
        </div>
    )
}

export default AdminButton

