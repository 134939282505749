import React, { useState } from 'react'
import Popup from '../../custom/Popup'
import ReactDOMServer from 'react-dom/server'
import { formatAttribute, formatScore } from "../../../HelperFunctions"

const MissingAttValButton = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const formatData = () => {
        let cell = props.data
        let res = "<div>"
        for (let i = 0 ; i < cell.length ; i++) {
            res += Object.entries(cell[i]).map(([key, value]) => "" + formatAttribute(key) + " = " + formatScore((key.substring(1, 3) == "ch" ? "CH" : "O*NET"), value)) + "<br>"
        }
        res += "</div>"
        return res
    }

    return (
        <div>
            <div className="missingAttValButtonDiv">
                <button className="missingAttValButton" onClick={togglePopup}>
                    Show All
                </button>
            </div>
            <div></div>
            {isOpen && 
                <Popup
                    content={
                        <>
                            <b>Missing Attributes and Values</b>
                            <hr/>
                            <div dangerouslySetInnerHTML={{ __html: formatData() }}></div>
                        </>
                    }
                    handleClose={togglePopup}
                />
            }
        </div>
    )
}

export default MissingAttValButton

