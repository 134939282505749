import React from "react"
import '../styles/ResultPage.scss'
import Header from '../components/result-page/Header'
import ResultUI from '../components/result-page/ResultUI'

// result page, used to show the results of the users input after running the input through the ENENOC pipeline
const ResultsPage = () => {
    return (
        <div className="resultPage">
            <Header />
            <ResultUI />
        </div>
    )
}
export default ResultsPage
