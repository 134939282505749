import React from "react";
import '../../styles/AdminPage.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table-next'
import { Spinner } from "react-bootstrap"

const RenderTable = (props) => {

    // fetching data from backend
    if (props.data === 'no_data') {
        return (
            <div id="spinnerDiv" style={{marginTop: '40px', marginBottom: '40px'}}>
                <Spinner id="theSpinner" animation="border" role="status" style={{ width: '5rem', height: '5rem' }}></Spinner>
                <div id="spinnerText" style={{marginTop: '0', textAlign: 'center' }}>Fetching Data...</div>
            </div>
        )
    }

    // returns column headers
    const getColumnHeaders = () => {

        // grabs headers (keys from dict)
        // const keys = Object.keys(props.data[0]);
        var keys = Object.keys(rowData[0]);

        // format headers into --> [{dataField: keys[i], text: keys[i]}, {...}]
        var result = []
        for (var i = 0; i < keys.length; i++) {
            result[i] = { dataField: keys[i], text: keys[i] }
        }

        // creating dummy header and setting it to the random unique key generated for each row
        let dummy = {
            dataField: 'uniqueKey',
            text: 'uniqueKey',
            isDummyField: true,
            hidden: true,
        }

        // setting random uniqueKey column as a dummy field so it doesnt show when rendered
        result[result.length - 1] = dummy

        return result;
    }

    // passes row data to attach a unique key for each entry (fixes error --> Warning: Each child in an array or iterator should have a unique "key" prop)
    const addUniqueKeyToData = (data) => {
        let theData = data

        for (let i = 0; i < theData.length; i++) {
            theData[i]['uniqueKey'] = i
        }

        return theData;
    }

    // row data with unique key
    const rowData = addUniqueKeyToData(props.data)

    // shows data/total depending on pagination
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );

    // options for pagination
    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        hideSizePerPage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: rowData.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    }

    const options = {
        page: 1,
        custom: true,
        totalSize: rowData.length
    };

    // incase db is broken
    if (props.data.length === 0) {
        return (<div>ERROR: Database is empty or broken and did not render correctly, if the database is an autocode then results won't appear until ~60 seconds. If everything seems normal and a reasonable amount of time has passed then contact a system administrator.</div>)
    }
    // data still loading
    else if (props.loading === true) {
        return (
            <div className="loadingOverlay">
                <div id="spinnerDiv" style={{marginTop: '40px', marginBottom: '40px'}}>
                    <Spinner id="theSpinner" animation="border" role="status" style={{ width: '5rem', height: '5rem' }}></Spinner>
                    <div id="spinnerText" style={{marginTop: '0', textAlign: 'center' }}>Fetching Data...</div>
                </div>
            </div>
        )
    }
    // render normally
    else {
        return (
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <div className="renderTableDiv" id="renderTableDivID">
                                <BootstrapTable
                                    keyField='uniqueKey'
                                    data={rowData}
                                    columns={getColumnHeaders()}
                                    striped
                                    hover
                                    condensed
                                    noDataIndication="Table is Empty"
                                    {...paginationTableProps}
                                />
                            </div>
                            <div className="paginationDiv">
                                <PaginationTotalStandalone
                                    {...paginationProps}
                                />
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                            </div>
                        </>
                    )
                }
            </PaginationProvider>
        );
    }
}

export default RenderTable
