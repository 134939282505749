import React from 'react'
import logo from '../../assets/return.png'

// styled for headers, will fit in most bodies but might need adjustments.

const XeroxBackButton = () => {
    
    const xeroxBack = () => {
        window.history.back();
    }

    return (
        <div className="xeroxButtonDiv">
            <button className="xeroxButton" onClick={xeroxBack} style={{position: 'absolute', left: '0', border: '1px solid white', width: '125px'}}>
                <img className="image" src={logo} alt="xerox back button logo" style={{marginRight: '10px', marginBottom: '2px'}}/>
                Back
            </button>
        </div>
    )
}

export default XeroxBackButton
