import React from 'react'
import '../styles/AdminPage.scss'
import { useAuth0 } from '@auth0/auth0-react'
import LoadingOverlay from 'react-loading-overlay';

// local
import HeaderBasic from "../components/admin-page/HeaderBasic"
import HeaderAdmin from "../components/admin-page/Header";
import LoadingPage from './LoadingPage'
import Dashboard from '../components/admin-page/Dashboard'
import Auth0LogoutButton from '../components/admin-page/buttons/Auth0LogoutButton';
import ReturnButton from '../components/admin-page/buttons/ReturnButton';
import Auth0LoginButton from '../components/admin-page/buttons/Auth0LoginButton';

// vars
var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else 
    backendPath = process.env.REACT_APP_BACKEND_PATH

const AdminPage = () => {
    const { user, isLoading, error, isAuthenticated } = useAuth0();

    // will display a loading screen when fetching data from Auth0.
    if (isLoading) {
        return (<LoadingPage />)
    }
    // error message
    else if (error) {
        return (
            <div>
                Oops... {error.message}
                <br />
                Contact a system administrator if this problem persists.
                <Auth0LoginButton />
                <Auth0LogoutButton />
            </div>
        )
    }
    // render specific components based off of authentication status
    else {
        // render greeting page if user is not autenticated, or the users role is not correct
        if (!isAuthenticated || (user["http://autonoc/roles"].toString() !== "Admin" && user["http://autonoc/roles"].toString() !== "Partner")) {
            return (
                <div className="adminLoginPage">
                    {/* header */}
                    <HeaderBasic />

                    <ReturnButton />

                    <h1>Welcome!</h1>

                    {/* let user know that they need to be authenticated */}
                    <p>
                        <b>This page is intended for administrators only.</b><br /><br />
                        Note: If you weren't given an account previously and you register for an account you will not be able to proceed after you login and will be redirected back to this welcome page.
                        Your account will have to be given special permissions by a system administrator to continue, please contact one for more details.
                    </p>
                    <br />
                    <Auth0LoginButton />
                    <Auth0LogoutButton />

                    {/* footer for copyright tag */}
                    <div style={{ marginTop: "600px" }}>&copy;<b>Copyright 2021 CBakerLab</b></div>
                </div>
            )
        }
        // render admin dashboard
        else {

            // POST request to check if authenticated user is in backend
            try {
                const requestData = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify(user)
                };
                fetch(backendPath + '/backend/venv/database/admin/userCheck', requestData)
                    .then(response => response.json())
                    .then(data => {
                        // reloading page for user since it's their first visit.
                        if (data === true) {
                            window.location.reload()
                        }
                    })
            } catch (error) {
                console.log(error);
            }

            return (
                <div className="adminPage">
                    {/* header */}
                    {/*<HeaderAdmin /> Disabled due to new Auth0 wrapper/header */}

                    {/* admin dashboard */}
                    <Dashboard user={user} />
                </div>
            )
        }
    }
}

export default AdminPage;


























/* Old Admin Page code without Auth0 */
// import React, { useState, useEffect } from "react";
// import LoginPage from '../components/admin-page/LoginPage';
// import HeaderAdmin from "../components/admin-page/Header";
// import Dashboard from "../components/admin-page/Dashboard";
// import HeaderFromResult from "../components/result-page/Header"
// import '../components/admin-page/AdminPage.scss'

// // admin page
// const AdminPage = () => {
//     // useState for if user is valid or not
//     const [valid, setValid] = useState('no');

//     // grabs/checks validity from login page, saves to local storage so they stay logged in. 
//     const handleValidity = (isItValid) => {

//         // valid
//         if (isItValid === 'valid') {
//             setValid('yes');
//             localStorage.setItem('valid', 'yes')
//         }
//         // not valid
//         if (isItValid === 'notvalid') {
//             setValid('no');
//             localStorage.setItem('valid', 'no')
//         }
//     }

//     // use effect hook, calls local storage after render and tells program if a user is signed in.
//     useEffect(() => {
//         const wasItValid = localStorage.getItem('valid');
//         if (wasItValid === 'yes') {
//             const wasValidOnRefresh = wasItValid;
//             setValid(wasValidOnRefresh);
//         }
//     }, []);

//     // no user logged in, render login page
//     if (valid === 'no') {
//         return (
//             <div className="adminLoginPage">
//                 {/* header */}
//                 <HeaderFromResult />

//                 {/* login page */}
//                 <LoginPage parentCallback={handleValidity} />
//             </div>
//         )
//     }

//     // user logged in, render admin dashboard
//     return (
//         <div className="adminPage">
//             {/* header */}
//             <HeaderAdmin />

//             {/* admin dashboard */}
//             <Dashboard />

//             {/* spacer */}
//             {/* <div className="fillerAdmin">asdsads</div> */}
//         </div>
//     )
// }
// export default AdminPage
