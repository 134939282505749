import React from "react"
import logo from '../assets/AUTONOC-logo.png'
import '../styles/ResultPage.scss'
import ReturnButton from "../components/admin-page/buttons/ReturnButton"

// 404 page for instances where user types in a bad url
const NotFoundPage = () => {
    return (
        <div className="notFound">
            <div className="logoDiv">
                <img className= "logo" src={logo} alt="sample logo for ENENOC" />
            </div>
            <h1>404</h1>
            <p>Page not Found</p>
            <ReturnButton />
        </div>
    )
}
export default NotFoundPage

