import React from 'react'
import logo from '../assets/taxonomyLogoOnly.PNG'
import '../styles/LoadingPage.scss'

const LoadingPage = () => {
    return (
        <div className="loadingPage">
            <img className="image" src={logo} alt="loading spinner"></img>
            <div className="text">Loading</div>
        </div>
    )
}

export default LoadingPage
