import React from 'react'
import '../styles/AdminPage.scss'

// local
import HeaderBasic from "../components/admin-page/HeaderBasic"
import ReturnButton from '../components/admin-page/buttons/ReturnButton'
import AdminButton from '../components/admin-page/buttons/AdminButton'

const AdminPageReroute = () => {
    return (
        <div className="adminLoginPage">
            {/* header/info */}
            <HeaderBasic />
            <ReturnButton/>
            <h1>Oops!</h1>

            {/* content */}
            <p>
                <b>This page is deprecated, continue to the new admin page.</b><br/><br/>
                The admin page has been moved to a new address, click the button below to be routed to it.
                Users may be greeted with a 'unsafe warning' message if it's their first visit, simply ignore this message and proceed to the admin page.
                For google chrome this will consist of a click on the 'advanced' link then clicking on the 'Proceed to 138.119.12.33 (unsafe)' link, other browsers should have a similar process.  
            </p>

            <br/>
            <AdminButton />

            {/* footer for copyright tag */}
            <div style={{marginTop: "600px"}}>&copy;<b>Copyright 2021 CBakerLab</b></div>
        </div>
    )
}

export default AdminPageReroute
