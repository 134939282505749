import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import { hasRole } from "./Auth0Util";

const LoginButton = () => {
    const { isAuthenticated, loginWithRedirect, user } = useAuth0();

    return (
        <Button
            variant="outline-success-autonoc"
            onClick={() =>
                loginWithRedirect({
                    redirectUri: window.location.origin + "/home"

                })
            }
        >
            Log in
        </Button>)
};

// this won't work here as we need the user object
const buildRedirectUri = (isAuthenticated, user) => {
    let userHasRole = isAuthenticated ? hasRole(user) : false;
    return window.location.origin + "/" + (userHasRole ? 'home' : 'not-allowed');
}

export default LoginButton;