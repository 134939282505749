import './Polyfills'  // must always be in first line of index js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { authConfig } from './auth.config';
import { checkUrlForXerox } from "./HelperFunctions.js";

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// checking for xerox device as it can't load site with Auth0, it's not needed for Xerox anyway so disabling it.
if (checkUrlForXerox() === true) {
  console.log("Loading without Auth0, not https or localhost due to Xerox device.")
  ReactDOM.render(<App />, document.getElementById('root'));
}
// rendering main application and setting Auth0 configurations
else if (window.location.protocol === 'https:' || (window.location.href).indexOf('localhost:3000') !== -1) {
  console.log("Loading with Auth0, server has correct parameters.")

  ReactDOM.render(
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      redirectUri={window.location.origin}  // default user redirect url on login - can be overridden in login button onClick. ***Path must be declared in Auth0's "Allowed Callback URLs" list.
      // onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <App />
    </Auth0Provider>,
    document.getElementById("root")
  );
}
// rendering main app with no Auth0
else {
  console.log("Loading without Auth0, not https or localhost.")
  ReactDOM.render(<App />, document.getElementById('root'));
}

