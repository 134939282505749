import React, { Component } from 'react';
import '../../styles/AdminPage.scss';
import { getFileName } from "../../HelperFunctions";
import Form from 'react-bootstrap/Form';

class Panels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            originalData: this.props.dataFromParent,
            currentData: this.props.dataFromParent,
            panelNum: '',
            allCheckboxValue: true,
            xeroxCheckboxValue: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.showPanels = this.showPanels.bind(this);
    }

    // after render of components, make sure if a panel was saved in local storage then style it to show it's currently clicked.
    componentDidMount = () => {
        if (localStorage.getItem('panelNum') !== null) {
            document.getElementById("db".concat(localStorage.getItem('panelNum'))).style.borderLeft = "12px solid whitesmoke";
        }
    }

    // handles click by sending which panel was clicked and sending to user
    handleClick = (e, dbIndex, dbList) => {

        // reset every panel before on click
        let curDbName = ""
        for (var i = 0; i < dbList.length; i++) {
            if (i == dbIndex) {
                curDbName = dbList[dbIndex]
            }

            try {
                document.getElementById("db".concat(i)).style.borderLeft = "none";
            }
            catch(e) { 
            }
        }

        // make current panel noticable
        document.getElementById("db".concat(dbIndex)).style.borderLeft = "12px solid whitesmoke";

        // set states current panel
        this.setState({ panelNum: dbIndex })

        // set local storage incase refresh
        localStorage.setItem('panelNum', dbIndex)

        // send current panel (from original list not filtered)
        // searches original list using current list to find original position
        let origPos = -1
        for (let i = 0; i < this.state.originalData.length; i++) {
            if (this.state.originalData[i] == curDbName) {
                origPos = i
                break
            }
        }
        this.props.parentCallback(origPos);
    }

    // renders all of the database names as buttons
    showPanels = (dbList) => {

        // don't render anything if dbList isn't defined yet
        if (typeof dbList === "undefined") {
            return;
        }

        // return each db in list as an html button
        return (
            dbList.map(
                // function (dbName) {
                dbName => {
                    // get index of db in list
                    var dbIndex = dbList.indexOf(dbName)
                    var actualFileName = getFileName(dbName)

                    // return button
                    if (this.props.loading === false) {
                        return <button id={"db".concat(dbIndex.toString())} className="panelButton" key={dbName} onClick={(e) => this.handleClick(e, dbIndex, dbList)}>&nbsp;&nbsp;&nbsp;{actualFileName}</button>;
                    }
                    // return button w/o on click because table data is loading
                    else {
                        return <button id={"db".concat(dbIndex.toString())} className="panelButton" key={dbName} onClick={() => alert("Please wait until the current table is done loading before switching.")}>&nbsp;&nbsp;&nbsp;{actualFileName}</button>;
                    }
                }
            )
        )
    }

    handleCheckboxChange = (event) => {
        if (event.target.name == "allCheckbox") {
            this.setState({ 
                allCheckboxValue : true,
                xeroxCheckboxValue: false,
                currentData: this.state.originalData
            })
        }
        else {
            let newData = this.state.originalData.filter(this.checkForXerox)
            this.setState({ 
                allCheckboxValue : false,
                xeroxCheckboxValue: true,
                currentData: newData
            })
        }
    }

    checkForXerox = (str) => {
        return str.includes("xerox_basic_") || str.includes("xerox_scan_")
    }

    // render panel list
    render() {
        return (
            <div className="panels">
                <h1><b>Database List</b></h1>
                <div id="checkboxDiv">
                    <div>
                        <input
                            type="checkbox"
                            id="allCheckbox"
                            name="allCheckbox"
                            value={this.state.allCheckboxValue}
                            checked={this.state.allCheckboxValue}
                            onChange={this.handleCheckboxChange}
                        />
                        &nbsp;All&nbsp;&nbsp;&nbsp;
                        <input
                            type="checkbox"
                            id="xeroxCheckbox"
                            name="xeroxCheckbox"
                            value={this.state.xeroxCheckboxValue}
                            checked={this.state.xeroxCheckboxValue}
                            onChange={this.handleCheckboxChange}
                        />
                        &nbsp;Xerox&nbsp;
                        {/*TODO for future */}
                        {/*<Form.Select>*/}
                        {/*    <option>Open this select menu</option>*/}
                        {/*    <option value="1">One</option>*/}
                        {/*    <option value="2">Two</option>*/}
                        {/*    <option value="3">Three</option>*/}
                        {/*</Form.Select>*/}
                    </div>
                    Total: {this.state.currentData.length}
                </div>
                <div className="panelsDiv">
                    {this.showPanels(this.state.currentData)}
                </div>
            </div>
        )
    }
}

export default Panels;


// old hardcoded Panel.js //

// // sets initial data, used for local storage instances
// const handleInitialData = (data) => {
//     if (typeof data === 'undefined'){
//         return data;
//     }
//     else {
//         return 1;
//     }
// }

// // panels, contains buttons used to shift through the databases.
// const Panels = (props) => {
//     // useState for current panel
//     const [panelNum, setPanelNum] = useState(handleInitialData(props.dataFromParent));

//     // on first render, display correct panel indent
//     // useEffect(() => {
//     //     // reset 
//     //     document.getElementById("testDB").style.borderLeft = "none";
//     //     document.getElementById("demoDB").style.borderLeft = "none";

//     //     // show cur panel clicked
//     //     if (panelNum === 1 || panelNum === '1') 
//     //         document.getElementById("testDB").style.borderLeft = "8px solid whitesmoke";
//     //     if (panelNum === 2 || panelNum === '2')
//     //         document.getElementById("demoDB").style.borderLeft = "8px solid whitesmoke";
//     // });  

//     // handles click by sending which panel was clicked and sending to user
//     const handleClick = (num) => {
//         // reset before on click
//         document.getElementById("testDB").style.borderLeft = "none";
//         document.getElementById("demoDB").style.borderLeft = "none";

//         // show user which panel is clicked
//         if (num === 1) {
//             document.getElementById("testDB").style.borderLeft = "8px solid whitesmoke";
//         }
//         else if (num === 2) {
//             document.getElementById("demoDB").style.borderLeft = "8px solid whitesmoke";
//         }

//         // set current panel
//         setPanelNum(num);

//         // set local storage incase refresh
//         localStorage.setItem('panelNum', num)

//         // pass data back to parent
//         props.parentCallback(num);
//     }

//     // use effect hook, calls local storage after render and tells program if a user is signed in.
//     useEffect(() => {
//         const curPanel = localStorage.getItem('panelNum');

//         // if a panel is set
//         if (curPanel !== null) {
//             // set cur button
//             setPanelNum(curPanel);
//         } 
//         else {
//             localStorage.setItem('panelNum', panelNum)
//             // handleClick(panelNum)
//         }

//         // reset 
//         document.getElementById("testDB").style.borderLeft = "none";
//         document.getElementById("demoDB").style.borderLeft = "none";

//         // show cur panel clicked
//         if (panelNum === 1 || panelNum === '1') 
//             document.getElementById("testDB").style.borderLeft = "8px solid whitesmoke";
//         if (panelNum === 2 || panelNum === '2')
//             document.getElementById("demoDB").style.borderLeft = "8px solid whitesmoke";


//     }, [panelNum]);

//     return (
//         <div className="panels">
//             <h1>Database List</h1>
//             <div className="panelsDiv">
//                 <button id="testDB" className="panelButton" onClick={() => handleClick(1)}>&nbsp;&nbsp;&nbsp;Test DB</button>
//                 <button id="demoDB" className="panelButton" onClick={() => handleClick(2)}>&nbsp;&nbsp;&nbsp;Demo DB</button>
//             </div>
//         </div>
//     )
// }

// export default Panels;
