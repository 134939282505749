import React, {useEffect, useState} from 'react';
import '../styles/components/NavBar.scss'
import { useAuth0 } from '@auth0/auth0-react'
import { Link, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/taxonomyLogoOnly.PNG'
import LogoutButton from "./auth0/LogoutButton";
import LoginButton from "./auth0/LoginButton";
import { hasRole, canAccessAdminPage } from "./auth0/Auth0Util";
import { HouseFill, InfoCircle, PersonBadge } from "react-bootstrap-icons";


const NavBar = () => {
    const { isLoading, isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    const history = useHistory();
    const [key, setKey] = useState(1)
    let userHasRole = isAuthenticated ? hasRole(user) : false;

    // force user to not-allowed
    useEffect(() => {
        if (isAuthenticated) {
            if (!userHasRole) {
                history.push('/not-allowed')
            }
        }
    }, [history, isAuthenticated, userHasRole])

    return (
        <>
            <Navbar sticky="top" expand="md" className="navBar" bg="light" data-bs-theme="light">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/">
                        <img
                            alt="taxonmy logo"
                            src={logo}
                            className="d-inline-block align-top"
                        />
                        OccupAI
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse className="justify-content-end">

                    {!isAuthenticated && (
                        <>
                            <LoginButton />
                        </>
                    )}

                    { isAuthenticated &&
                        <>
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                                variant="tabs"
                                defaultActiveKey={key}
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey={1} disabled={!userHasRole} as={Link} to="/home">
                                        <span>
                                            <HouseFill /> Home
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey={2} disabled={!userHasRole} as={Link} to="/matrix">
                                        <span>
                                            <InfoCircle /> Matrix
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={3} disabled={!userHasRole || !canAccessAdminPage} as={Link} to="/admin">
                                        <span>
                                            <PersonBadge /> Admin
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Navbar.Text>
                                {formatUser(user)}
                            </Navbar.Text>
                            <LogoutButton/>
                        </>
                    }

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

const formatUser = (user) => {
    let str = user.name
    let role = user["http://autonoc/roles"].toString()
    if (role) {
        return str + " (" + role + ")"
    }
    else {
        return str + " (N/A)"
    }
}

const buildKey = () => {

}

export default NavBar;