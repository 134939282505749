import React from "react";
import * as ReactDOMServer from 'react-dom/server';
import { withRouter } from "react-router-dom"
import '../../../styles/NeighbourPage.scss';
import RenderNeighbourTable from "./RenderNeighbourTable";
import trashCanLogo from "../../../assets/delete.png"
import { Spinner } from "react-bootstrap"
import Accordion from 'react-bootstrap/Accordion';
import { formatAttribute } from "../../../HelperFunctions"
import submitButtonLogo from '../../../assets/refresh.png'
import AllAttAndValueButton from './AllAttAndValButton'

var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else
    backendPath = process.env.REACT_APP_BACKEND_PATH

backendPath = backendPath + '/backend/venv/matrix/'

var searchModeLimits = {
    'NOC': 10,
    'SOC': 10,
    'NOC_SOC': 80
}

class NeighbourUi extends React.Component {

    constructor(props) {
        super(props);

        // get search mode
        let searchMode = this.props.match.params.mode
        let searchByNoc = false
        let searchBySoc = false
        let searchByNocAndSoc = false
        if (searchMode == "NOC")
            searchByNoc = true
        else if (searchMode == "SOC")
            searchBySoc = true
        else if (searchMode == "NOC_SOC")
            searchByNocAndSoc = true
        else {
            searchByNoc = true
            this.props.history.push('/matrix/neighbour/mode=NOC&codes=' + this.props.match.params.codes)
        }

        this.state = {
            nocCode: this.props.match.params.codes.substring(0, this.props.match.params.codes.indexOf("[")),
            nocCodeTitle: null,
            socCodeList: this.props.match.params.codes.substring(this.props.match.params.codes.indexOf("[") + 1, this.props.match.params.codes.indexOf("]")),
            chosenSocCode: null,
            chosenSocCodeTitle: null,
            neighbourTableData: '',
            sameAttributeLimit: this.searchModeHelper(searchByNoc, searchBySoc, searchByNocAndSoc), // since default mode is NOC only
            attributeAndValueList: [], 
            attributeValuesHtml: "<select/>",
            selectedAttribute: null,
            selectedAttribute2: null,
            selectedAttributeValue: null,
            overridedAttributesAndValues: [], // items should be {col_name : col_val} format
            overridedAttributesAndValues2: [],
            attributesAndValuesForCurrentCode: null,
            isLoading: false,
            loadingOverlayActive: false,
            searchByNocIsChecked: searchByNoc,
            searchBySocIsChecked: searchBySoc,
            searchByNocAndSocIsChecked: searchByNocAndSoc
        }
        this.state.chosenSocCode = this.state.socCodeList.split(',')[0]
        this.getTitlesForNocAndSoc(this.state.nocCode, this.state.chosenSocCode)
        this.getAttributesForNocAndSoc(this.state.nocCode, this.state.chosenSocCode)
        this.handleSpecificMatrixSubmit()
        this.getAllAttributesAndValues() // do not move this, things will break :D // TODO what if we move this to after load?
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value })
    }

    handleSelectTagFromSocCodeListChange = (event) => {
        this.setState({ 
            [event.target.name]: event.target.value 
        }, () => {
            // this.handleSpecificMatrixSubmit()
            this.getTitlesForNocAndSoc(this.state.nocCode, this.state.chosenSocCode)
        });
    }

    handleOverrideAttributeChange = (event) => {
        this.setState({ 
            [event.target.name]: event.target.value,
            attributeValuesHtml: this.renderSelectTagForOverridingAttributeValues(event.target.value, null)
        }, () => {});
    }

    getTitlesForNocAndSoc = async (nocCode, socCode) => {
        let body = {
            nocCode: nocCode,
            socCode: socCode
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + "getTitlesForNocAndSoc", requestData)
                .then(res => res.json())
                .then(data => {
                    this.setState({ 
                        nocCodeTitle : data["nocCodeTitle"],
                        chosenSocCodeTitle : data["socCodeTitle"]
                    })
                });

        } catch (error) {
            console.log(error);
        }
    }

    getAttributesForNocAndSoc = async (nocCode, socCode) => {
        let body = {
            nocCode: nocCode,
            socCode: socCode
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + "getAllAttributesForNocAndSocCode", requestData)
                .then(res => res.json())
                .then(data => {
                    this.setState({ 
                        attributesAndValuesForCurrentCode: data
                    })
                });

        } catch (error) {
            console.log(error);
        }
    }

    getAllAttributesAndValues = async () => {
        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({searchMode: this.getSearchMode()})
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + "neighbour/getAllAttributesAndValuesForMatrix", requestData)
                .then(res => res.json())
                .then(data => {
                    data = this.createNewAttributeNames(data)
                    let curAtt = Object.keys(data[0])[0]
                    // return data
                    this.setState({ 
                        attributeAndValueList : data,
                        selectedAttribute: curAtt,
                        selectedAttribute2: curAtt,
                        attributeValuesHtml: this.renderSelectTagForOverridingAttributeValues(curAtt, data),
                    }, () => {})
                });

        } catch (error) {
            console.log(error);
        }
    }

    // re-format key into a more readable name and attach to obj
    createNewAttributeNames = (data) => { 
        for (let i = 0 ; i < data.length ; i++) {
            data[i].newName = formatAttribute(Object.keys(data[i])[0])
        }
        return data
    }

    renderSelectTagForOverridingAttributeValues(attribute, list) {
        let valueList = null
        if (list == null) {
            valueList = this.state.attributeAndValueList.find(x => Object.keys(x)[0] == attribute)
        
        }
        else {
            valueList = list.find(x => Object.keys(x)[0] == attribute)
        }
        valueList = Object.values(valueList)[0]

        let res = ""
        for (let i = 0 ; i < valueList.length ; i++) {
            res += "<option value=\"" + valueList[i] + "\">" + valueList[i] + "</option>"
        }

        let element = <select className={"overridingAttributeValues"} name={"selectedAttributeValue"} onChange={this.handleChange} dangerouslySetInnerHTML={{__html: res}} />
        return ReactDOMServer.renderToString(element)
    }

    // handleBasicMatrixSubmit = async () => {
    //     this.setState({
    //         isLoading : true
    //     })
        
    //     let body = {
    //         nocCode: this.state.nocCode,
    //         socCode: this.state.chosenSocCode,
    //         sameAttributeLimit: this.state.sameAttributeLimit,
    //         searchMode: this.getSearchMode()
    //     }

    //     try {
    //         // creating POST request
    //         const requestData = {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json'
    //             },
    //             body: JSON.stringify(body)
    //         };

    //         // fetching data and waiting until response is resolved before setting state
    //         fetch(backendPath + "neighbour/getBasicMatrixNeighbourData", requestData)
    //             .then(res => res.json())
    //             .then(data => {
    //                 this.setState({ 
    //                     neighbourTableData: data,
    //                     isLoading: false
    //                 });
    //             }
    //         )

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    handleSpecificMatrixSubmit = () => {
        this.setState({
            isLoading : true
        })

        let body = {
            nocCode: this.state.nocCode,
            socCode: this.state.chosenSocCode,
            overrideColumns: this.state.overridedAttributesAndValues,
            overrideColumns2: this.state.overridedAttributesAndValues2,
            sameAttributeLimit: this.state.sameAttributeLimit,
            searchMode: this.getSearchMode()
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + "neighbour/getSpecificMatrixNeighbourData", requestData)
                .then(res => res.json())
                .then(data => {
                    this.setState({ 
                        neighbourTableData: data,
                        isLoading: false
                    });
                }
            )
        } catch (error) {
            console.log(error);
        }
    }

    getSearchMode = () => {
        let searchMode = ""
        if (this.state.searchByNocIsChecked)
            searchMode = "NOC"
        else if (this.state.searchBySocIsChecked)
            searchMode = "SOC"
        else 
            searchMode = "NOC_SOC"
        return searchMode
    }

    handleCheckboxChange = (event) => {
        let okButton = window.confirm("Are you sure you want to switch modes? This will refresh the page.")
        if (okButton) {
            let searchByNoc = false
            let searchBySoc = false
            let searchByNocAndSoc = false
            let modeName = null

            if (event.target.name == "searchByNoc") {
                searchByNoc = true
                modeName = "NOC"
            }
            else if (event.target.name == "searchBySoc") {
                searchBySoc = true
                modeName = "SOC"
            }
            else {
                searchByNocAndSoc = true
                modeName = "NOC_SOC"
            }
            
            this.setState({ 
                searchByNocIsChecked : searchByNoc,
                searchBySocIsChecked: searchBySoc,
                searchByNocAndSocIsChecked: searchByNocAndSoc,
                sameAttributeLimit: this.searchModeHelper(searchByNoc, searchBySoc, searchByNocAndSoc)
            })

            this.getAllAttributesAndValues()
            
            this.props.history.push('/matrix/neighbour/mode=' + modeName + "&codes=" + this.props.match.params.codes)
            window.location.reload(false);
        }
    }

    searchModeHelper = (searchByNoc, searchBySoc, searchByNocAndSoc) => {
        let limit = searchModeLimits.NOC
        if (searchBySoc)
            limit = searchModeLimits.SOC
        else if (searchByNocAndSoc)
            limit = searchModeLimits.NOC_SOC
        return limit
    }

    createSelectTagFromSocCodeList(className, socCodeList, stateKeyName) {
        if (!Array.isArray(socCodeList)) {
            socCodeList = socCodeList.split(',')
        }
        let res = ""
        let first = true
        for (let i = 0 ; i < socCodeList.length ; i++) {
            if (first == true) {
                res += "<option value=\"" + socCodeList[i] + "\" selected=\"selected\">" + socCodeList[i] + "</option>"
                first = false
            }
            else {
                res += "<option value=\"" + socCodeList[i] + "\">" + socCodeList[i] + "</option>"
            }
        }

        // set default value
        if (this.state[stateKeyName] == null)
            this.state[stateKeyName] = socCodeList[0]

        return <select className={className} name={stateKeyName} onChange={this.handleSelectTagFromSocCodeListChange} dangerouslySetInnerHTML={{__html: res}} />
    }

    createSelectTagForSameAttributeLimit() {
        let res = ""
        
        for (let i = 1 ; i <= this.state.attributeAndValueList.length; i++) {
            if (i == this.state.sameAttributeLimit) {
                res += "<option value=\"" + i + "\" selected=\"selected\">" + i + "</option>"
            }
            else {
                res += "<option value=\"" + i + "\">" + i + "</option>"
            }
        }

        return <select className={"sameAttributeLimitDiv"} name={"sameAttributeLimit"} onChange={this.handleChange} dangerouslySetInnerHTML={{__html: res}} />
    }

    // this is for the "add" attribute AND value UI //
    createSelectTagForOverridingAttributes() {
        let attList = this.state.attributeAndValueList
        let res = ""
        for (let i = 0 ; i < attList.length ; i++) {
            let att = attList[i]
            let attName = Object.keys(att)[0]
            let newAttName = att['newName']
            res += "<option value=\"" + attName + "\">" + newAttName + "</option>"
        }

        return <select className={"overridingAttributesDiv"} name={"selectedAttribute"} onChange={this.handleOverrideAttributeChange} dangerouslySetInnerHTML={{__html: res}} />
    }

    addToOverrideList = () => {
        let attValElement = document.getElementsByClassName("overridingAttributeValues")[0];
        let attValValue = attValElement.value;

        // need to check list to make sure the attribute doesn't already exist, if it does remove it before adding new one
        let list = this.state.overridedAttributesAndValues
        for (let i = 0; i < list.length ; i++) {
            if (list[i]["attribute"] == this.state.selectedAttribute) {
                list.splice(i, 1)
                break
            }
        }

        // push att/val pair
        list.push({
            attribute: this.state.selectedAttribute,
            value: attValValue
        })
    
        this.setState({overridedAttributesAndValues: list})
    }

    delInOverrideList = (attributeToDelete) => {
        let list = this.state.overridedAttributesAndValues
        for (let i = 0; i < list.length ; i++) {
            if (list[i]["attribute"] == attributeToDelete) {
                list.splice(i, 1)
                break
            }
        }
        this.setState({overridedAttributesAndValues: list})
    }

    resetOverrideList = () => {
        this.setState({overridedAttributesAndValues: []})
    }

    // this is just for the "remove" attribute from search UI. Just a slightly modified copy of the other UI, bad impl but works for now.. //
    createSelectTagForOverridingAttributes2() {
        let attList = this.state.attributeAndValueList
        let res = ""
        for (let i = 0 ; i < attList.length ; i++) {
            let att = attList[i]
            let attName = Object.keys(att)[0]
            let newAttName = att['newName']
            res += "<option value=\"" + attName + "\">" + newAttName + "</option>"
        }

        return <select className={"overridingAttributesDiv2"} name={"selectedAttribute2"} onChange={this.handleChange} dangerouslySetInnerHTML={{__html: res}} />
    }

    addToOverrideList2 = () => {
        // need to check list to make sure the attribute doesn't already exist, if it does remove it before adding new one
        let list = this.state.overridedAttributesAndValues2
        for (let i = 0; i < list.length ; i++) {
            if (list[i]["attribute"] == this.state.selectedAttribute2) {
                list.splice(i, 1)
                break
            }
        }

        list.push({
            attribute: this.state.selectedAttribute2,
        })
    
        this.setState({overridedAttributesAndValues2: list})
    }

    delInOverrideList2 = (attributeToDelete) => {
        let list = this.state.overridedAttributesAndValues2
        for (let i = 0; i < list.length ; i++) {
            if (list[i]["attribute"] == attributeToDelete) {
                list.splice(i, 1)
                break
            }
        }
        this.setState({overridedAttributesAndValues2: list})
    }

    resetOverrideList2 = () => {
        this.setState({overridedAttributesAndValues2: []})
    }

    convertAttToNewName = (attName) => {
        let val = this.state.attributeAndValueList.find(item => attName == Object.keys(item)[0])
        if (val == undefined || val == null)
            return attName
        else 
            return val['newName']
    }

    render() {

        return (

            <div className="NeighbourUi">

                <h1 className="matrixNeighbourHeader"><strong>Job Transition Matrix: NOC & SOC Neighbours</strong></h1>

                <Accordion className="notesDiv" defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><span>Notes:</span></Accordion.Header>
                        <Accordion.Body>
                            Adding an attribute and value pair to the override list under the "Add Specific Attribute and Value to Search" header will ensure that the neighbours found will contain those attribute and value pairs. 
                            <br/><br/>
                            Adding an attribute to the remove list under the "Remove Specific Attribute from Search" header will ensure that the neighbours search logic will not include that attribute and all of it's values. 
                            <br/><br/>
                            By selecting a value in the "The number of same attributes is greater than" dropdown, our logic will only return neighbours with a "# of equivalent attributes" count that is equal to or higher than the selected value.
                            <br/><br/>
                            Lastly, the "Refresh Data" button will refresh the table and include the user changes in it's search.
                            <br/><br/>
                            <div>
                                Attribute Totals per Search Mode:
                                <ul>
                                    <li>NOC = 19</li>
                                    <li>SOC = 68</li>
                                    <li>NOC and SOC = 87</li>
                                </ul>
                            </div>
                            *Complicated search criteria's could take ~5-20 seconds.
                            <br/>
                            *Attributes declared in the "remove" list will always overrule the attribute/value pair in the "override" list.
                            <br />
                            *In both lists, the trash-can icon next to the list item can be used to remove that item from the list.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <div className="nocAndSocCodeLabelsDiv">
                    <div className="nocAndSocCodeLabelsDivTitle">
                        <div style={{display: 'inline-block'}}>
                            <b><u>NOC/SOC Pair:</u></b>
                        </div>
                        <div style={{display: 'inline-block', marginLeft: '1em'}}>
                            <AllAttAndValueButton data={this.state.attributesAndValuesForCurrentCode}/>
                        </div>
                    </div>
                    <div className="nocCodeLabelDiv">
                        <label>NOC Code:&nbsp;&nbsp;&nbsp;{this.state.nocCode} - {this.state.nocCodeTitle}</label>
                        <br/>
                        <label>SOC Code:&nbsp;&nbsp;&nbsp;    
                            {this.createSelectTagFromSocCodeList("socCodeList", this.state.socCodeList, "chosenSocCode")} - {this.state.chosenSocCodeTitle}
                        </label>
                    </div>
                </div>

                <div className="overrideValuesDiv">
                    <div className="overrideValuesDivTitle"><b><u>"Add" Specific Attribute and Value to Search:</u></b></div>
                    <div className="overridingAttributeValuesDiv">
                        Attributes: {this.createSelectTagForOverridingAttributes()} 
                        Values:<div id="valueUIDiv" dangerouslySetInnerHTML={{__html: this.state.attributeValuesHtml}}></div>
                        <button className="overrideListUtilityButtons" id="addAttributeToOverrideListButton" onClick={this.addToOverrideList}>Add</button>
                        <button className="overrideListUtilityButtons" id="resetAttributeToOverrideListButton" onClick={this.resetOverrideList}>Reset</button>
                    </div>

                    {/* creates list dynamically from override att/val list */}
                    <div style={{marginTop: "0.5em"}}>
                        {"Override List -> " + (this.state.overridedAttributesAndValues.length == 0 ? "Empty" : "")}
                        <ul className="overrideList">
                            {this.state.overridedAttributesAndValues.map((listitem, index) => (
                                <li key={index} style={{marginTop:"0.5em"}}>
                                    {this.convertAttToNewName(listitem["attribute"]) + " = " + listitem["value"]}
                                    &nbsp;&nbsp;
                                    <button id="overrideListDeleteButton" style={{width:"5em !important", marginLeft: '0.5em !important'}} onClick={() => this.delInOverrideList(listitem["attribute"])}>
                                        <img className="image" src={trashCanLogo} alt="trash can logo" style={{marginTop: '-0.2em'}}/>
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="overrideValuesDiv2">
                    <div className="overrideValuesDivTitle2"><b><u>"Remove" Specific Attribute from Search:</u></b></div>
                    <div className="overridingAttributeValuesDiv2">
                        Attributes: {this.createSelectTagForOverridingAttributes2()}
                        <button className="overrideListUtilityButtons" id="addAttributeToOverrideListButton" onClick={this.addToOverrideList2}>Add</button>
                        <button className="overrideListUtilityButtons" id="resetAttributeToOverrideListButton" onClick={this.resetOverrideList2}>Reset</button>
                    </div>

                    {/* creates list dynamically from override att/val list */}
                    <div style={{marginTop: "0.5em"}}>
                        {"Remove List -> " + (this.state.overridedAttributesAndValues2.length == 0 ? "Empty" : "")}
                        <ul>
                            {this.state.overridedAttributesAndValues2.map((listitem, index) => (
                                <li key={index} style={{marginTop:"0.5em"}}>
                                    {this.convertAttToNewName(listitem["attribute"])}
                                    &nbsp;&nbsp;
                                    <button id="overrideListDeleteButton" style={{width:"5em !important", marginLeft: '0.5em !important'}} onClick={() => this.delInOverrideList2(listitem["attribute"])}>
                                        <img className="image" src={trashCanLogo} alt="trash can logo" style={{marginTop: '-0.2em'}}/>
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                
                <div className="attributeLimitDiv"><b>The number of same attributes is greater than or equal to:</b>&nbsp;&nbsp;{this.createSelectTagForSameAttributeLimit()}</div>

                <div className="submitButtonDiv">
                    <div className="checkboxDiv">
                        <b>Search Criteria:</b>&nbsp;&nbsp;
                        <input 
                            type="checkbox" 
                            id="searchByNoc"
                            name="searchByNoc"
                            value = {this.state.searchByNocIsChecked}
                            checked={this.state.searchByNocIsChecked}
                            onChange={this.handleCheckboxChange}
                        />
                        &nbsp;NOC&nbsp;&nbsp;&nbsp;
                        
                        <input 
                            type="checkbox" 
                            id="searchBySoc"
                            name="searchBySoc"
                            value = {this.state.searchBySocIsChecked}
                            checked={this.state.searchBySocIsChecked}
                            onChange={this.handleCheckboxChange}
                        />
                        &nbsp;SOC&nbsp;&nbsp;&nbsp;

                        <input 
                            type="checkbox" 
                            id="searchByNocAndSoc"
                            name="searchByNocAndSoc"
                            value = {this.state.searchByNocAndSocIsChecked}
                            checked={this.state.searchByNocAndSocIsChecked}
                            onChange={this.handleCheckboxChange}
                        />
                        &nbsp;NOC and SOC
                    </div>
                    <button className="submitButton" onClick={this.handleSpecificMatrixSubmit}>
                        <img className="image" src={submitButtonLogo} alt="refresh button logo - https://icons8.com/icons/" style={{width: '1.5em', marginRight: '10px', marginBottom: '2px', marginLeft: '5px'}}/>
                        Refresh Data
                    </button>
                </div>
                
                {this.state.isLoading !== true && this.state.neighbourTableData !== '' ? 
                    <RenderNeighbourTable data={(typeof this.state.neighbourTableData === "undefined") ? 'no_data' : this.state.neighbourTableData} loading={this.state.loadingOverlayActive} />
                    :
                    <div>
                        <Spinner id="theSpinner" animation="border" role="status" style={{ marginTop: '5em', width: '7em', height: '7em' }}></Spinner>
                        <br/>
                        Fetching Data...
                    </div>
                }

                <br/>

            </div>
        );
    }
}
export default withRouter(NeighbourUi);