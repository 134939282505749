import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../../assets/signout.png'

const Auth0LogoutButton = () => {
    const { logout, isAuthenticated } = useAuth0();

    return (
        isAuthenticated && (
            <div className="logoutDiv">
                {/* <button className="logout" onClick={() => logout({ returnTo: window.location.origin })}> */}
                <button className="logout" onClick={() => logout()}>
                    <img className="logoutLogo" src={logo} alt="signout logo" />
                    Logout
                </button>
            </div>
        )
    )
}

export default Auth0LogoutButton
