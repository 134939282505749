import React from "react";
import '../../styles/InputPage.scss';
import logo from '../../assets/AUTONOC-logo.png';
import { useParams } from 'react-router-dom';
import XeroxHomeButton from "../xerox/XeroxHomeButton";
import XeroxBackButton from "../xerox/XeroxBackButton";
import {checkUrlForXerox} from "../../HelperFunctions.js";

const Details = () => {

    // grabbing xerox device url from params 
    const { xeroxDeviceUrl } = useParams();

    return (
        <div className="leftSide">

            {/* (special case) users on Xerox devices need a back button due to no URL on MFDs */}
            {checkUrlForXerox() === true ? 
                <div id="xeroxButtonContainer">
                    <XeroxHomeButton xeroxDeviceUrl={xeroxDeviceUrl}/>
                    <XeroxBackButton /> 
                </div>
                : 
                <></>
            }

            {/* website logo */}
            <div className="logoDiv">
                <img className="logo" src={logo} alt="sample logo for ENENOC" />
            </div>

            <h1>Automated Occupation Coding</h1>

            {/* line under title */}
            <hr className="line"></hr>

            {/* details about site */}
            <div className="detailsDiv">
                <p className="detailsInfo">
                    You have been sent to this webpage to allow the recording of your job title for this pilot study. Please enter your job title below and click “Submit”.

                    On the next page you will be asked to choose which one of 5 job categories that most closely described your occupation.
                </p>
            </div>

            {/* Copyright tag for left-side when neccesary*/}
            <footer className="copyrightLeftside">&copy; Copyright 2021 CBakerLab</footer>
        </div>
    )
}

export default Details


