import React, { Component } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../styles/ResultPage.scss';
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next";
import infoIcon from '../../assets/information-blue.png';
import Feedback from "./Feedback";
import JobListPopup from "./JobListPopup";
import { withAuth0 } from '@auth0/auth0-react';

// vars
var backendDatabase = ''
if (window.location.protocol === 'https:')
    backendDatabase = process.env.REACT_APP_BACKEND_PATH_SECURE + "/backend/venv/database"
else 
    backendDatabase = process.env.REACT_APP_BACKEND_PATH + "/backend/venv/database"

class ResultUI extends Component {

    // constructor to hold important variables from GET request
    constructor(props) {
        super(props);

        // gets previous pages (homepage) response data
        this.theResponse = this.props.location.state.data;

        // set the data from response to our state
        this.state = {
            uid: this.theResponse.uid,
            ip: '',
            city: '',
            region: '',
            country: '',
            userInput: this.theResponse.input,
            industry: this.theResponse.industry,
            feedback: '',
            code: this.theResponse.code,
            groupTitle: this.cleanJSONString(this.theResponse.groupTitle),
            jobTitle: this.theResponse.jobTitle,
            leadStatement: this.cleanJSONString(this.theResponse.leadStatement),
            show: false,
            dataForJobPopup: "",

            // xerox vars
            xerox: this.theResponse.xerox,
            xeroxUsername: this.theResponse.xeroxUsername,
            xeroxEmail: this.theResponse.xeroxEmail,
            xeroxMode: this.theResponse.xeroxMode,
            xeroxDeviceUrl: this.theResponse.xeroxDeviceUrl
        }
        this.userSelection = false;
        this.showJobPopup = this.showJobPopup.bind(this);
        this.hideJobPopup = this.hideJobPopup.bind(this);
        this.user = null;
    }

    // shows the job list popup 
    showJobPopup = (rowID) => {
        this.setState({
            show: true,
            dataForJobPopup: this.state.jobTitle[rowID]
        });
    };

    // hides the job list popup
    hideJobPopup = () => {
        this.setState({ show: false });
    };

    // sets all of the data for the result table
    dataForTable = () => {
        return {
            data: [
                {
                    id: 1,
                    code: this.state.code[0],
                    groupTitle: this.state.groupTitle[0],
                    jobTitle: this.configureJobTitleList(this.state.jobTitle[0]),
                    // moreDetails: this.moreDetailFormatter(0)
                },
                {
                    id: 2,
                    code: this.state.code[1],
                    groupTitle: this.state.groupTitle[1],
                    jobTitle: this.configureJobTitleList(this.state.jobTitle[1]),
                },
                {
                    id: 3,
                    code: this.state.code[2],
                    groupTitle: this.state.groupTitle[2],
                    jobTitle: this.configureJobTitleList(this.state.jobTitle[2]),
                },
                {
                    id: 4,
                    code: this.state.code[3],
                    groupTitle: this.state.groupTitle[3],
                    jobTitle: this.configureJobTitleList(this.state.jobTitle[3]),

                },
                {
                    id: 5,
                    code: this.state.code[4],
                    groupTitle: this.state.groupTitle[4],
                    jobTitle: this.configureJobTitleList(this.state.jobTitle[4]),
                },
                {
                    id: '-',
                    code: 'N/A',
                    groupTitle: "None of the above Job Categories match my job title.",
                }
            ],
            columns: [
                {
                    dataField: 'id',
                    text: 'No.',
                    align: 'center',
                    headerStyle: () => {
                        return { width: '60px', textAlign: 'center' };
                    },

                },
                {
                    dataField: 'groupTitle',
                    text: 'Job Categories',
                    headerStyle: () => {
                        return { width: '35%', minWidth: '50px' };
                    },
                },
                {
                    dataField: 'jobTitle',
                    text: 'Job Titles',
                    headerStyle: () => {
                        return { width: '35%' };
                    },
                },
                {
                    dataField: 'moreDetails',
                    text: 'More Details',
                    headerStyle: () => {
                        return { width: '150px', textAlign: 'center' };
                    },
                    // 
                    formatter: this.moreDetailFormatter
                },
            ]
        }
    }

    // formats the more details column so we can place our component inside of it.
    moreDetailFormatter = (row, rowIndex) => {
        if (rowIndex.id === "-") {
            return (<div></div>)
        }
        else {
            return (
                <div className="infoButtonDiv">
                    <input className="infoButton" onClick={() => this.showJobPopup(rowIndex.id - 1)} type="image" src={infoIcon} alt="info logo aquired from uxwing.com w/ no attribution or credit acquired" />
                </div>
            );
        }
    };

    // handles everything about the selection rows
    selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selectionHeaderRenderer: () => 'Choice',
        // selectionRenderer: ({ mode, ...rest }) => (
        //     <input type={mode} {...rest} />
        // ),
        style: {
            border: '2px solid #07243d',
            backgroundColor: '#e8f0fe72',
        },
        // styling
        headerStyle: () => {
            return { width: '1000px', textAlign: 'center !important' };
        },
        // sets the user selection var everytime a row is clicked
        onSelect: (row, rowIndex) => {
            this.userSelection = row.id - 1

            // user clicks none of the above, set to N/A instead of row id
            if (Number.isNaN(this.userSelection) === true)
                this.userSelection = 'N/A'

            // console.log("User selected row: ", this.userSelection);
            // console.log("NOC: ", this.state.code[this.userSelection])
        }
    };

    // handles everything for the expand row/column, shows them the job description and titles
    expandRow = {
        // renders components onto the expand row
        renderer: row => (
            <div>
                <p>Job Description:  <br /></p>
                <p>{`${this.state.leadStatement[row.id - 1]}`}</p>
                {/* calling popup component to post full job title list whenever button is clicked */}
                <div id="popupContainer">
                    List of job titles &rarr;<button onClick={() => this.showJobPopup(row.id - 1)}>Jobs</button>
                </div>
            </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right',
        onlyOneExpanding: true,
        nonExpandable: ["-"],
        // header title 
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return 'More Details \u25B2';
            }
            return 'More Details \u25BC';
        },
        // column logo
        expandColumnRenderer: ({ expanded }) => {
            return (
                <div className="infoButtonDiv">
                    <input className="infoButton" type="image" src={infoIcon} alt="info logo aquired from uxwing.com w/ no attribution or credit acquired" />
                </div>
            );
        }
    };

    // hides the entire row if a code was not generated for it
    checkForEmptyRows = () => {
        const rowsToHide = [];
        for (var i = 0; i < 5; i++) {

            // checks to see if each row has a corresponding code
            if (typeof (this.state.code[i]) === "undefined") {
                const hidingRow = i + 1;

                rowsToHide.push(hidingRow)
            }
        }

        return rowsToHide;
    }

    // function used to reformat huge JSON job title list into a clean, limited, and relevant list that shows top 5 jobs in accordance (exact match or substring) to the users input string.
    configureJobTitleList = (theData) => {
        if (typeof (theData) != "undefined") {

            // taking JSON object and splitting it up into an array
            var myString = JSON.stringify(theData);
            var myArray = myString.split(';');

            // scan the list to see if any job title matches the given input
            var relevantArray = [];
            var perfectMatch = false;
            for (var i = 0; i < myArray.length; i++) {

                // if the input is apart of any job title in the array then save that element in a new array
                if (myArray[i].includes((this.state.userInput).toLowerCase())) {
                    relevantArray.push(myArray[i]);

                    // if the input has a perfect match then save for later
                    if (myArray[i].trim() === this.state.userInput.trim()) {
                        perfectMatch = true;
                    }
                }
            }

            // limits result for reading purposes
            var limit = 5;
            var limitedArray;
            var shuffled;
            var limitedAsHTML;

            // use relevant array because it fits limit or is greater than limit
            if (relevantArray.length >= limit) {
                if (relevantArray.length === limit)
                    limitedArray = relevantArray;
                else {
                    shuffled = relevantArray.sort(() => 0.5 - Math.random());
                    limitedArray = shuffled.slice(0, limit);
                }
            }
            // uses the relevant array with random job title additions to fill the limit
            else if (relevantArray.length < limit && relevantArray.length > 0) {
                shuffled = myArray.sort(() => 0.5 - Math.random());
                limitedArray = shuffled.slice(0, limit - relevantArray.length);
                limitedArray = relevantArray.concat(limitedArray);
            }
            // there were no relevant job titles so just shuffle array and take random job titles according to limit
            else {
                shuffled = myArray.sort(() => 0.5 - Math.random());
                limitedArray = shuffled.slice(0, limit);
            }

            // if the input matched a job title perfectly, it will go at the top of list before clean so that we can erase it's duplicate.
            if (perfectMatch === true) {
                var str = this.state.userInput.trim()
                limitedArray.unshift(" ".concat(str));
            }

            // cleaning data
            limitedArray = this.cleanJobList(limitedArray);

            // creating an html list with the limited array via mapping
            var count = 0;
            limitedAsHTML = limitedArray.map(
                function (job) {
                    // match or substring of input given is highlighted
                    if (count < (relevantArray.length)) {
                        count++;
                        return <li key={job}><b>{job}</b></li>;
                    }
                    // basic
                    else {
                        return <li key={job}>{job}</li>;
                    }
                }
            )

            return limitedAsHTML;
        }
    }

    // cleans the lists for duplicates, unwanted characters, or empty strings.
    cleanJobList = (arr) => {
        // remove duplicates (rare circumstance, but do it anyway for readability purposes) 
        arr = arr.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })

        // check each string element and remove special characters
        for (var i = 0; i < arr.length; i++) {
            // remove " and /
            arr[i] = arr[i].replace("\"", "");
        }

        // remove element from list if empty string
        arr = arr.filter(function (str) {
            return /\S/.test(str);
        });

        // removes duplicates again incase the filter missed
        for (i = 0; i < arr.length; i++) {
            if (this.state.userInput === arr[i]) {
                arr.pop(i)
            }
        }

        return arr;
    }

    // used to clean JSON lists from certain characters
    cleanJSONString = (arr) => {
        // replace the ';' instances
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].replace(";", "");
        }

        return arr
    }

    // handles the submission of the users result to the backend which will processed into the database
    handleSubmit = async (event) => {

        // when no row is selected, prompt the user and tell them to try again
        if (this.userSelection === false) {
            alert('Try again! Please select a row before submitting...');
            return;
        }

        // (Not needed right now)
        // // grabbing clients IP
        // try {
        //     await fetch('http://api.ipstack.com/check?access_key=9b45140dd641ae519c7a88717aad6ca9')
        //         .then(response => response.json())
        //         .then(data => {
        //             this.setState({
        //                 ip: data.ip,
        //                 city: data.city,
        //                 region: data.region_name,
        //                 country: data.country_name
        //             });
        //         });
        // } catch (error) {
        //     console.log(error)
        // }
        // grabbing clients IP

        this.setState({
            ip: 'no',
            city: 'no',
            region: 'no',
            country: 'no'
        });

        // N/A was clicked, ask for feedback
        if (this.userSelection === "N/A") {
            document.getElementById("popupContainer").style.display = "block";
        }
        // send data straight to backend because a selection was made
        else {
            const data = {
                uid: this.state.uid,
                ip: this.state.ip,
                city: this.state.city,
                region: this.state.region,
                country: this.state.country,
                userInput: this.state.userInput,
                industry: this.state.industry,
                feedback: this.state.feedback,
                enenocResults: (this.state.code).join("; "),
                code: this.state.code[this.userSelection],
                xerox: this.state.xerox,
                xeroxUsername: this.state.xeroxUsername,
                xeroxEmail: this.state.xeroxEmail,
                xeroxMode: this.state.xeroxMode,
                xeroxDeviceUrl: this.state.xeroxDeviceUrl,
                user: this.user
            }

            // processing result to backend
            try {
                fetch(backendDatabase, {
                    method: 'POST',
                    body: JSON.stringify(data)
                }).then(function (response) {
                    console.log('Sent POST request')
                    return response.status;
                });
                event.preventDefault();

                // rerouting to results page once backend api is finished
                console.log(data['uid'])
                if (data['uid'] !== 'no_uid')   // TODO can do this better in the future, but as of right now hardcoded. ex: for a admin that want's their users for UIDS be send to a specific url after submission is done, then we can check their db and check the uids to see if they match then send em. flow -> switch case where if uid is apart of admin1 db then send to this url, if admin2 db then send to this url, etc
                    window.location.href = "https://qfreeaccountssjc1.az1.qualtrics.com/jfe/form/SV_51NCKrixbiwLWjs"
                else
                    this.props.history.push('/thank_you', {
                        xeroxDeviceUrl : this.state.xeroxDeviceUrl,
                        autonocResults : this.state.code
                    })

            } catch (error) {
                console.log(error);
            }
        }
    }

    // only used when feedback is entered, ask for feedback, then send it with other data to backend 
    handleFeedbackSubmit = (feedback, event) => {
        const data = {
            uid: this.state.uid,
            ip: this.state.ip,
            city: this.state.city,
            region: this.state.region,
            country: this.state.country,
            userInput: this.state.userInput,
            industry: this.state.industry,
            feedback: feedback,
            enenocResults: (this.state.code).join("; "),
            code: 'N/A',
            xerox: this.state.xerox,
            xeroxUsername: this.state.xeroxUsername,
            xeroxEmail: this.state.xeroxEmail,
            xeroxMode: this.state.xeroxMode,
            xeroxDeviceUrl: this.state.xeroxDeviceUrl
        }

        // processing result to backend
        try {
            fetch(backendDatabase, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(function (response) {
                console.log('Sent POST request');
                return response.status;
            });
            // event.preventDefault();

            // rerouting to results page once backend api is finished
            this.props.history.push('/thank_you', {
                xeroxDeviceUrl : this.state.xeroxDeviceUrl,
                autonocResults : this.state.code
            })

        } catch (error) {
            console.log(error);
        }
    }

    // rendering html components
    render() {
        const { user } = this.props.auth0;
        this.user = user

        // calls functions to set data for table
        const tableData = this.dataForTable()

        // calls function to check if a row should be hidden
        const hiddenRowKeys = this.checkForEmptyRows();

        return (
            <div className='resultUI'>

                {/* Header for enenoc */}
                <h1 className="resultHeader"><strong>AUTONOC Search Results</strong></h1>

                {/* div for user input */}
                <div className='inputDiv'>
                    Results for Job Title: <u><b>{this.state.userInput}.</b></u>
                </div>

                {/* div for extra info to user */}
                <div className='infoDiv'>
                    {/* <b>Please check the box or click the row for which number best describes your job title and then click "Submit".</b> */}
                    <b>Please choose the row which best describes your job title and then click "Submit".</b>
                </div>

                {/* table */}
                <div className="tableDiv">
                    <BootstrapTable
                        hover
                        keyField='id'
                        data={tableData.data}
                        columns={tableData.columns}
                        hiddenRows={hiddenRowKeys}
                        selectRow={this.selectRow}
                    // expandRow={this.expandRow}
                    />
                </div>

                {/* submit button, handles the submission of user selection */}
                <form className="buttonForm" onClick={this.handleSubmit.bind(this)}>
                    <input className="submitButton" type="button" name="submit" value="Submit" />
                </form>

                {/* footer for copyright tag */}
                <div className="footerDiv">
                    <div className="copyright">&copy;Copyright 2021 CBakerLab</div>
                </div>

                {/* calling custom feedback popup component */}
                <div id="popupContainer" style={{ display: 'none' }}>
                    <Feedback id="myPopup" parentCallback={this.handleFeedbackSubmit}></Feedback>
                </div>

                {/* calling custom job list component for each row to show full job list */}
                <JobListPopup show={this.state.show} handleClose={this.hideJobPopup} jobData={this.state.dataForJobPopup}>
                    <p>Modal</p>
                </JobListPopup>
            </div>
        )
    }
}

export default withRouter(withAuth0(ResultUI))