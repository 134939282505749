import React, { Component } from 'react';
import '../../styles/ResultPage.scss';

class Feedback extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            feedback: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    // handles the change to the states text area variable when input is entered
    handleChange = (event) => {
        this.setState({ feedback: event.target.value });
    }

    // handles the button click event 
    onSubmitFeedback = (event) => {
        this.props.parentCallback(this.state.feedback);
        event.preventDefault();
    }

    render() {
        return (
            <div className="popupDiv" >
                <div className="popupInner">
                    <h1><strong>Submit Feedback!</strong></h1>

                    <p>Your feedback is important for helping us figure out why these results were not satisfactory. Please let us know why you clicked “none of the above”.</p>

                    {/* form for user to submit feedback */}
                    <form className="formDiv" onSubmit={this.onSubmitFeedback}>
                        <textarea className="inputBox" onChange={this.handleChange} type="text" name="textarea" value={this.state.feedback} maxLength="150"/>
                        <input className="theButton" type="submit" name="accesscode" value="Submit" />
                    </form>
                </div>
            </div>
        )
    }
}

export default Feedback;