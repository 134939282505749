import React from "react";
import { withRouter } from "react-router-dom"
import '../../../styles/ComparePage.scss';
import searchIcon from '../../../assets/magnifier-glass.png'
import compareButtonLogo from '../../../assets/refresh.png'
import RenderCompareTable from "./RenderCompareTable";
import Accordion from 'react-bootstrap/Accordion';

var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else
    backendPath = process.env.REACT_APP_BACKEND_PATH

class CompareUi extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nocCode: this.grabNocCodeFromUrl(this.props.match.params.codes1),
            nocCodeTitle: "",
            socCodeList: this.grabSocCodeFromUrl(this.props.match.params.codes1),
            socCodeListTitles: [],
            nocCodeToCompare: this.grabNocCodeFromUrl(this.props.match.params.codes2),
            nocCodeToCompareTitle: "",
            socCodeListToCompare: this.grabSocCodeFromUrl(this.props.match.params.codes2),
            socCodeListToCompareTitles: [],
            chosenSocCode: null,
            chosenSocCodeToCompare: null,
            compareTableData: '',
            numOfSameAttributes: "N/A",
            attributeInfo: '',
            loadingOverlayActive: false,
            allNocCodes: []
        }

        this.getAllNocCodes()
    }

    componentDidMount = () => {
        this.initCompareInfo()
    }

    initCompareInfo = () => {
        this.handleCompareClick()

        // utilizing swap button functionality to grab job titles for initial code pairs passed through URL.
        this.handleSwapButtonClick("nocCode", this.state.nocCode)
        this.handleSwapButtonClick("nocCodeToCompare", this.state.nocCodeToCompare) 
    }

    grabNocCodeFromUrl = (codes) => {
        return codes.substring(0, codes.indexOf("["))
    }

    grabSocCodeFromUrl = (codes) => {
        return codes.substring(codes.indexOf("[") + 1, codes.indexOf("]"))
    }

    getAllNocCodes = () => {
        try {
            // creating POST request
            const requestData = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                // body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + "/backend/venv/matrix/getAllNocCodes", requestData)
                .then(res => res.json())
                .then(data => {
                    this.setState({allNocCodes : data["noc_codes"]})
                });

        } catch (error) {
            console.log(error);
        }
    }

    // handles the change to the state variable when input is entered
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSwapButtonClick = async (targetName, targetValue) => {
        // if (!this.validateNocCode(targetValue)) // disabling as we choose from a pre-set dropdown, so no need for validation anymore
        //     return

        this.getCodePairData(targetName, targetValue)
    }

    handleSwapButtonClickAfterFetch = (targetName, targetValue, codePairData) => {
        if (codePairData.length === 0) {
            alert("The NOC code that you entered \"" + targetValue + "\" does not exist in our job transition matrix, please try a different code.")
        }
        
        let socCodeList = []
        let socCodeListTitles = []
        for (let i = 0; i < codePairData.length; i++) {
            socCodeList.push(codePairData[i].socCode)
            socCodeListTitles.push(codePairData[i].socCodeTitle)
        }

        this.setState({ 
            [targetName]: codePairData[0].nocCode,
            [(targetName === "nocCode") ? "nocCodeTitle" : "nocCodeToCompareTitle"]: codePairData[0].nocCodeTitle,
            [(targetName === "nocCode") ? "socCodeList" : "socCodeListToCompare"]: socCodeList,
            [(targetName === "nocCode") ? "socCodeListTitles" : "socCodeListToCompareTitles"]: socCodeListTitles,
            [(targetName === "nocCode") ? "chosenSocCode" : "chosenSocCodeToCompare"]: socCodeList[0] // grab first one by default
        });
    }

    getCodePairData = (targetName, targetValue) => {
        let body = {
            nocCode: targetValue,
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + "/backend/venv/matrix/getCodePairDataByNocCode", requestData)
                .then(res => res.json())
                .then(data => {
                    this.handleSwapButtonClickAfterFetch(targetName, targetValue, data)
                });

        } catch (error) {
            console.log(error);
        }
    }

    handleCompareClick = () => {
        if (!this.validateNocCode(this.state.nocCode))
            return
        if (!this.validateNocCode(this.state.nocCodeToCompare))
            return

        this.setState({
            loadingOverlayActive: true,
        }, () => {})

        // creating body for POST
        let body = {
            nocCode: this.state.nocCode,
            nocCodeToCompare: this.state.nocCodeToCompare,
            socCode: this.state.chosenSocCode,
            socCodeToCompare: (this.state.socCodeListToCompare == null) ? null : this.state.chosenSocCodeToCompare
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + '/backend/venv/matrix/compare/getMatrixCompareData', requestData)
                .then(res => res.json())
                .then(data => {
                    if (data["tableData"] == false) {
                        alert("The noc code entered was not apart of the database or the input was invalid.")
                        window.location.reload(false);
                    }
                    else {
                        this.setState({ 
                            compareTableData: data["tableData"],
                            numOfSameAttributes: this.calcSameAttributes(data["tableData"]),
                            attributeInfo: data['attributeInfo']
                        },
                        () => {
                            this.setState({
                                loadingOverlayActive: false,
                            }, () => {})
                        });
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }

    getOptionsFromSelectTagAsList(className) {
        var selectBoxEl = document.getElementsByClassName(className);
        var arrayOfNodes = selectBoxEl.childNodes;
        var optionsArr = [];

        // loop through child Nodes and only get option nodes
        for (var i = 0; i < arrayOfNodes.length; i++) {
            if (arrayOfNodes[i].nodeName === 'OPTION') {
            optionsArr.push(arrayOfNodes[i].textContent);
          }
        }

        return optionsArr
    }

    createSelectTagFromSocCodeList(className, socCodeList, socCodeListTitles, stateKeyName) {
        if (!Array.isArray(socCodeList)) {
            socCodeList = socCodeList.split(',')
        }
        let res = ""
        let first = true
        for (let i = 0 ; i < socCodeList.length ; i++) {
            if (first == true) {
                res += "<option value=\"" + socCodeList[i] + "\" selected=\"selected\">" + (socCodeList[i] + " - " + socCodeListTitles[i])  + "</option>"
                first = false
            }
            else {
                res += "<option value=\"" + socCodeList[i] + "\">" + (socCodeList[i] + " - " + socCodeListTitles[i]) + "</option>"
            }
        }

        // set default value
        if (this.state[stateKeyName] == null)
            this.state[stateKeyName] = socCodeList[0]

        return <select className={className} name={stateKeyName} onChange={this.handleChange} dangerouslySetInnerHTML={{__html: res}} />
    }

    calcSameAttributes = (tableData) => {
        let res = 0;
        try {
            for (let i = 0 ; i < tableData.length ; i++) {
                let keys = Object.keys(tableData[i])
                if (tableData[i][keys[1]] == tableData[i][keys[2]]) {
                    res++
                }
            }
        }
        catch {
            return "N/A"
        }

        return res
    }

    validateNocCode = (nocCode) => {
        let res = true

        // must be a non-empty entry, checking by JS incase built in HTML5 validation isn't working 
        if (nocCode === undefined || nocCode === '') {
            alert("Try again! The noc code to compare field is mandatory, please enter a noc code!");
            res = false;
        }
        else if (nocCode.length != 6 || !nocCode.includes(".")) {
            alert("The NOC code must be in the following format \"0000.0\", for example: 2133.0.")
            res = false
        }

        return res
    }

    render() {

        return (

            <div className="CompareUi">

                <div className="legendDiv">
                    <p>1. CH = Canadian Career Handbook {"(Canada)"}&nbsp;</p>
                    <p>2. O*NET = Occupational Information Network {"(USA)"}</p>
                </div>

                <h1 className="matrixCompareHeader"><strong>Job Transition Matrix: NOC & SOC Comparison</strong></h1>

                <Accordion className="notesDiv" defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><span>Notes:</span></Accordion.Header>
                        <Accordion.Body>
                            {"The attributes prefixed with \"ch_\" refer to Canada's Career Handbook attributes. The scoring system here differs as we wanted to make all the values numerical, to do this we removed all of the letters, hyphens (-), and commas (,) with blanks. Specifically, for column \"ch_employment_requirements\" we changed all the plus signs (+) to zeroes (0) and the \"R\"s to nines (9)."}
                            <br/><br/>
                            *The "Refresh Data" button will refresh the table and include the user changes in it's search.
                            <br/><br/>
                            For more information you can visit the following links:
                            <br/>
                            <a href="https://noc.esdc.gc.ca/CareerHandbook/DescriptionSections?objectid=PdsC2lTuhQWpLuWeiL5kbor%2FVr9mtNwEDNOovK%2FPs1A%3D" target="_blank">Canadian Career Handbook Attributes</a>
                            <br/>
                            <a href="https://www.onetonline.org/find/descriptor/browse/1.A" target="_blank">Occupational Information Network Attributes</a>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <div className="nocAndSocCodeLabelsDiv">
                    <div className="nocCodeLabelDiv">
                        <p><b><u>NOC/SOC Pair 1:</u></b></p>
                        <label>
                            NOC Code:&nbsp;&nbsp;&nbsp;{this.state.nocCode}
                            &nbsp;- {this.state.nocCodeTitle}
                        </label>
                        <br/>
                        <label>SOC Code:&nbsp;&nbsp;&nbsp;    
                            {this.createSelectTagFromSocCodeList("socCodeList", this.state.socCodeList, this.state.socCodeListTitles, "chosenSocCode")}
                        </label>
                    </div>
                </div>
                
                <br/>

                <div className="nocAndSocCodeLabelsDiv">
                    <div className="socCodeLabelDiv">
                        <p><b><u>NOC/SOC Pair 2:</u></b></p>
                        <label>
                            NOC Code:&nbsp;&nbsp;&nbsp;{this.state.nocCodeToCompare}
                            &nbsp;- {this.state.nocCodeToCompareTitle}
                        </label>
                        <br/>
                        <label>SOC Code:&nbsp;&nbsp;&nbsp;
                            {this.state.socCodeListToCompare != null ? 
                                this.createSelectTagFromSocCodeList("socCodeListToCompare", this.state.socCodeListToCompare, this.state.socCodeListToCompareTitles, "chosenSocCodeToCompare")
                                :
                                <></>
                            }
                        </label>
                    </div>
                </div>
                
                <div className="switchNocCodeParentDiv">
                    <div className="switchNocCodeParentHeader"><b><u>Change Noc Codes:</u></b></div>
                    <div className="switchNocCodeDiv">
                        <div className="inputAndButtonDiv">
                            {/* <div className="inputFieldDiv"> */}
                                {/* <img src={searchIcon} alt="search logo acquired from uxwing.com w/ no attribution or credit acquired" /> */}
                                {/* <input
                                    id="nocCodeInputField"
                                    type="text"
                                    value={this.state.nocCodeInputField}
                                    name="nocCodeInputField"
                                    onChange={this.handleChange}
                                    placeholder="Example: 2133.0"
                                    spellCheck="value"
                                    maxLength="6"
                                    required
                                /> */}
                            {/* </div> */}
                            <span>Change Noc Code for NOC/SOC Pair 1:</span>
                            
                            <div>
                                {this.state.allNocCodes != null ? 
                                    <select id="nocCodeInputField" name="nocCodeInputField" onChange={this.handleChange} defaultValue={this.state.allNocCodes[0]}>
                                        {this.state.allNocCodes.map((cur, index) => { 
                                            return (<option value={cur} key={index}>{cur}</option>);
                                        })}
                                    </select>
                                    :
                                    <></>
                                }
                            </div>
                            
                            <div className="buttonDiv">
                                <button onClick={() => this.handleSwapButtonClick("nocCode", this.state.nocCodeInputField)} id="swap" className="swapButton">Swap Noc 1</button>
                            </div> 
                        </div>
                    </div>
                    
                    <div className="switchNocCodeDiv" style={{marginTop: '10px'}}>
                        <div className="inputAndButtonDiv">
                            {/* <div className="inputFieldDiv"> */}
                                {/* <img src={searchIcon} alt="search logo acquired from uxwing.com w/ no attribution or credit acquired" /> */}
                                {/* <input
                                    id="nocCodeToCompareInputField"
                                    type="text"
                                    value={this.state.nocCodeToCompareInputField}
                                    name="nocCodeToCompareInputField"
                                    onChange={this.handleChange}
                                    placeholder="Example: 2133.0"
                                    spellCheck="value"
                                    maxLength="6"
                                    required
                                /> */}
                            {/* </div> */}

                            <span className="nocSocPair2Label">Change Noc Code for NOC/SOC Pair 2:</span>
                           
                            <div>
                                {this.state.allNocCodes != null ? 
                                    <select id="nocCodeToCompareInputField" name="nocCodeToCompareInputField" onChange={this.handleChange} defaultValue={this.state.allNocCodes[0]}>
                                        {this.state.allNocCodes.map((cur, index) => { 
                                            return (<option value={cur} key={index}>{cur}</option>);
                                        })}
                                    </select>
                                    :
                                    <></>
                                }
                            </div>
                            
                            <div className="buttonDiv">
                                <button onClick={() => this.handleSwapButtonClick("nocCodeToCompare", this.state.nocCodeToCompareInputField)} id="swap" className="swapButton">Swap Noc 2</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="utilityDiv">

                    <div className="sameAttributeCountDiv">
                        <b>Number of same attributes: {this.state.numOfSameAttributes}</b>
                    </div>

                    <div className="compareButtonDiv">
                        <button className="compareButton" onClick={this.handleCompareClick}>
                            <img className="image" src={compareButtonLogo} alt="refresh button logo - https://icons8.com/icons/" style={{width: '24px', marginRight: '10px', marginBottom: '2px'}}/>
                            Refresh Data
                        </button> 
                    </div>
                </div>
                
                {this.state.compareTableData !== '' ? 
                    <RenderCompareTable 
                        data={(typeof this.state.compareTableData === "undefined") ? 'no_data' : this.state.compareTableData} 
                        loading={this.state.loadingOverlayActive} 
                        attributeInfo={this.state.attributeInfo}
                        compareUiState={this.state}
                    />
                    :
                    <></>
                }

                <br/>

            </div>
        );
    }
}
export default withRouter(CompareUi);