import React from 'react'
import logo from '../../assets/home.png'

// styled for headers, will fit in most bodies but might need adjustments.

const XeroxHomeButton = (props) => {

    const xeroxHome = () => {
        window.location.href = decodeURIComponent(props.xeroxDeviceUrl)
    }

    let cssPos = 'absolute'
    if (props.overwriteCssPosition === true) {
        cssPos = 'relative'
    }

    return (
        <div className="xeroxButtonDiv">
            <button className="xeroxButton" onClick={xeroxHome} style={{position: cssPos, left: '0', marginTop: '-15px', border: '1px solid white', width: '125px'}}>
                <img className="image" src={logo} alt="xerox home button logo" style={{marginRight: '10px', marginBottom: '2px'}}/>
                Home
            </button> 
        </div>
    )
}

export default XeroxHomeButton
