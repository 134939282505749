import React from 'react'
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const CustomTooltip = (props) => {
    let className = props.overrideClassName
    let placement = props.placement // top, right, bottom, or left

    return (
        <>
            <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                    <Tooltip 
                        className={(className != undefined || className != null || className != "") ? className : `tooltip-${placement}`}
                        // id={`tooltip-${placement}`}
                        // style={{
                        //     color: "white !important" // TODO not working..
                        // }}
                    >
                        <div className='tooltipInnerDiv'>
                            {props.tooltipBody}
                        </div>
                    </Tooltip>
                }
            >
                {/* <Button variant="secondary"></Button> */}
                {props.componentToWrap}
            </OverlayTrigger>
        </>
    )
}

export default CustomTooltip;