import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { hasRole } from "./Auth0Util";
import LoadingPage from "../../pages/LoadingPage";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();

    let selectedPath = Array.isArray(path) ? path[0] : path // handles multiple path declarations - TODO is it possible to pass url the client wants instead of picking first as default?
    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: selectedPath }
            });
        };
        fn();
        }, [loading, isAuthenticated, loginWithRedirect, selectedPath])
  const render = props =>
      isAuthenticated === true ? <Component {...props} /> : null

  return <Route path={selectedPath} render={render} {...rest} />
};

export default PrivateRoute;