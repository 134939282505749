import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NavBar from './components/NavBar'
import PrivateRoute from './components/auth0/PrivateRoute'
import { useAuth0 } from "@auth0/auth0-react";

//Pages
import LandingPage from "./pages/LandingPage";
import HomePage from "./pages/Home";
import NotFoundPage from './pages/NotFound404';
import ResultsPage from './pages/Results';
import ThankYou from './pages/ThankYou';
import AdminPage from './pages/AdminPage';
import AdminPageReroute from './pages/AdminPageReroute';
import MatrixPage from './pages/MatrixPage';
import ComparisonPage from './pages/ComparePage';
import NeighbourPage from './pages/NeighbourPage';
import NotAllowedPage from "./pages/NotAllowedPage";
import LoadingPage from "./pages/LoadingPage";

// main component that links all the other components together - acts as the hub
export const App = () => {
  const { isLoading } = useAuth0()

  return (
    <BrowserRouter>
      <header>
        <NavBar/>
      </header>

      { isLoading &&
        <LoadingPage/>
      }

      { !isLoading &&
        <Switch>
          <Route exact path={"/"} component={LandingPage}/>

          {/* home page aka the main UI page, allowing a unique identifier to be passed through url */}
          <PrivateRoute exact path={[
            "/home",
            "/home/uid=:uid",
            "/home/xerox/uid=:uid&xeroxDeviceUrl=:xeroxDeviceUrl",
            "/home/xerox/xerox=:xerox&xeroxUsername=:xeroxUsername&xeroxEmail=:xeroxEmail&xeroxMode=:xeroxMode&xeroxDeviceUrl=:xeroxDeviceUrl"
          ]} component={HomePage}/>

          <PrivateRoute exact path={["/home", "/home/uid=:uid"]} component={HomePage}/>

          {/*result page after the ENENOC processes user input*/}
          <PrivateRoute exact path="/results" component={ResultsPage}/>

          <PrivateRoute exact path="/thank_you" component={ThankYou}/>

          {/* admin page or admin reroute page depending on protocol/web-server */}
          {window.location.protocol === 'https:' || (window.location.href).indexOf('localhost:3000') !== -1
              ?
              <PrivateRoute exact path="/admin" component={AdminPage}/>
              :
              <PrivateRoute exact path="/admin" component={AdminPageReroute}/>
          }

          {/* matrix lookup home page */}
          <PrivateRoute exact path={[
            "/matrix",
            "/matrix/jobTitle=:jobTitle",
            "/matrix/codes=:codes",
            "/matrix/xeroxDeviceUrl=:xeroxDeviceUrl",
            "/matrix/jobTitle=:jobTitle&xeroxDeviceUrl=:xeroxDeviceUrl",
            "/matrix/codes=:codes&xeroxDeviceUrl=:xeroxDeviceUrl"
          ]} component={MatrixPage}/>

          {/* matrix lookup comparison page */}
          <PrivateRoute exact path={[
            // "/matrix/compare/codes=:codes",
            // "/matrix/compare/codes=:codes&xeroxDeviceUrl=:xeroxDeviceUrl"
            "/matrix/compare/codes1=:codes1&codes2=:codes2",
            "/matrix/compare/codes1=:codes1&codes2=:codes2&xeroxDeviceUrl=:xeroxDeviceUrl"
          ]} component={ComparisonPage}/>

          {/* matrix lookup neighbours page */}
          <PrivateRoute exact path={[
            "/matrix/neighbour/codes=:codes",
            "/matrix/neighbour/codes=:codes&xeroxDeviceUrl=:xeroxDeviceUrl",
            "/matrix/neighbour/mode=:mode&codes=:codes",
            "/matrix/neighbour/mode=:mode&codes=:codes&xeroxDeviceUrl=:xeroxDeviceUrl"
          ]} component={NeighbourPage}/>

          <Route exact path="/not-allowed" component={NotAllowedPage}/>

          {/*incase user enters something arbitrary*/}
          <Route exact path="/404" component={NotFoundPage}/>
          <Redirect to="/404"/>

        </Switch>
      }
    </BrowserRouter>
  )
}
export default App;
