import React, { Component } from "react"
import '../../styles/InputPage.scss';
import searchIcon from '../../assets/magnifier-glass.png'
import { withRouter } from "react-router-dom"
import { Spinner } from "react-bootstrap"
import Filter from "bad-words"

// variables
var backendLink = ''
if (window.location.protocol === 'https:')
    backendLink = process.env.REACT_APP_BACKEND_PATH_SECURE + "/backend/venv/api"
else 
    backendLink = process.env.REACT_APP_BACKEND_PATH + "/backend/venv/api"

class InputUI extends Component {

    // constructor to hold the states form info in JSON format
    constructor(props) {
        super(props);
        this.state = {
            jobTitle: '',
            industry: '',
            // getting unique ID from url
            uid: this.props.match.params.uid,
            // xerox vars
            xerox: this.props.match.params.xerox,
            xeroxUsername: this.props.match.params.xeroxUsername,
            xeroxEmail: this.props.match.params.xeroxEmail,
            xeroxMode: this.props.match.params.xeroxMode,
            xeroxDeviceUrl: this.props.match.params.xeroxDeviceUrl,
        };
    }

    // handles the change to the state variable when input is entered
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    // handles the submission of form data to the backend api (aka the ENENOC)
    handleSubmit = async event => {
        event.preventDefault();

        // must be a non-empty entry, checking by JS incase built in HTML5 validation isn't working 
        if (this.state.jobTitle === '') {
            alert("Try again! The job title field is mandatory, please enter your job title!");
            return;
        }

        // script to validate input, exit if bad. 
        const inputIsGood = this.inputValidation();
        if (inputIsGood === false) {
            document.getElementById("jobTitle").value = "";
            document.getElementById("industry").value = "";
            this.state.jobTitle = "";
            this.state.industry = "";
            return;
        }

        // disabling button once clicked
        document.getElementById("submit").disabled = true;

        // showing spinner until backend call is done
        document.getElementById("theSpinner").style.color = "#07243d";
        document.getElementById("spinnerText").style.display = "block";

        // sending data to backend via a async/await fetch request, calling async/await so page only reroutes once the ENENOC is complete
        try {
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(this.state)
            };
            // sending input to ENENOC and awaiting for response before continuing
            const response = await fetch(backendLink, requestData);
            const data = await response.json();
            console.log("Fetch success.")

            // execution of backend is finished so pushing API response to results page
            this.props.history.push({
                pathname: '/results',
                state: { data }
            })

        } catch (error) {
            console.log(error);
        }
    }

    // validate input before passing through 
    inputValidation = () => {

        // checks if input contains a bad word
        var filter = new Filter();
        if (filter.isProfane(this.state.jobTitle) === true || filter.isProfane(this.state.industry) === true) {
            alert("Try again! Your input contains a bad word, please enter something appropiate...");
            return false;
        }

        // unwanted characters (html tags, )
        var unwantedChars = "!@#$%^&*()+=[]\\;/{}|:<>?~_";

        // scanning both the job title and insustry
        var count = 0;
        var curString = '';
        while (count < 2) {
            // checking job title
            if (count === 0)
                curString = this.state.jobTitle;
            // checking industry
            else {
                curString = this.state.industry;
            }

            // scan through each char of input string
            for (var i = 0; i < curString.length; i++) {
                // get cur char
                const cur = (curString).charAt(i);

                // check to see if cur char is includedd in unwanted char list
                if (unwantedChars.indexOf(cur) !== -1) {
                    alert("Try again! Don't use any special characters besides the following: . , - ' \"")
                    return false
                }
            }

            count = count + 1;
        }

        // passed all checks
        return true;
    }

    //rendering html components
    render() {

        return (
            <div className="rightSide">

                {/* Header for enenoc */}
                <h1 className="enenocHeader"><strong>AUTONOC Search</strong></h1>

                {/* UI form for user input */}
                <form className="inputUIForm" onSubmit={this.handleSubmit.bind(this)} action={backendLink} method="post">
                    
                    {/* get the users job title (mandatory) */}
                    <label>Enter your job title:<br></br></label>
                    
                    <div className="inputAndButtonDiv">
                        <div className="inputFieldDiv">
                            <img src={searchIcon} alt="search logo aquired from uxwing.com w/ no attribution or credit acquired" />
                            <input
                                id="jobTitle"
                                type="text"
                                value={this.state.value}
                                name="jobTitle"
                                onChange={this.handleChange}
                                placeholder="Example: Electrical Engineer"
                                spellCheck="value"
                                maxLength="75"
                                required
                            />
                        </div>
                    </div>

                    {/* get the users industry (optional) */}
                    <label>Enter your industry (optional):<br></br></label>

                    <div className="inputAndButtonDiv">
                        <div className="inputFieldDiv">
                            <img src={searchIcon} alt="search logo aquired from uxwing.com w/ no attribution or credit acquired" />
                            <input
                                id="industry"
                                type="text"
                                value={this.state.value}
                                name="industry"
                                onChange={this.handleChange}
                                placeholder="Example: Electronics"
                                spellCheck="value"
                                maxLength="75"
                            />
                        </div>
                        <div className="buttonDiv">
                            <button id="submit" className="submitButton"><span>Submit</span></button>
                        </div>
                    </div>
                </form>

                {/* Hiding spinner until form is submitted */}
                <div id="spinnerDiv">
                    <Spinner id="theSpinner" animation="border" role="status" style={{ width: '5rem', height: '5rem' }}></Spinner>
                    <div id="spinnerText" style={{ display: 'none', marginTop: '0', textAlign: 'center' }}>Searching</div>
                </div>

                {/* Copyright tag for right-side when necessary */}
                <div className="copyrightRightsideDiv">
                    <p className="copyrightRightside">&copy; Copyright 2021 CBakerLab</p>
                </div>

            </div>
        )
    }
}

// have to wrap export in withRouter() to allow the reroute
export default withRouter(InputUI)