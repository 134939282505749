import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Auth0LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    // not logged in so render button
    !isAuthenticated && (
      <button className="loginButton" onClick={() => loginWithRedirect()}>
        Log In
      </button>
    )
  )
};

export default Auth0LoginButton;