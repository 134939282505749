import React from "react";
import ReactDOM from 'react-dom'
import '../../styles/AdminPage.scss';
import Alert from 'react-bootstrap/Alert';
import { getFileName } from "../../HelperFunctions";

// local
import Panels from "./Panels";
import Profile from "./Profile";
import LoadingPage from "../../pages/LoadingPage"
import RenderTable from "./RenderTable";
import DeleteButton from "./buttons/DeleteButton";
import ReturnButton from "./buttons/ReturnButton";
import RefreshButton from "./buttons/RefreshButton";
import ExportButton from "./buttons/ExportButton";
import AutoCodeButton from "./buttons/AutoCodeButton";
import Auth0LogoutButton from "./buttons/Auth0LogoutButton";
import UploadAndEmailButton from "./buttons/UploadAndEmailButton";

// vars
var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else
    backendPath = process.env.REACT_APP_BACKEND_PATH

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.user = this.props.user;
        this.permissions = this.getPermissions();
        this.initialPanel = this.setInitialPanel();
        this.dbList = this.getDatabaseList();
        this.state = {
            userPermissions: this.permissions,
            currentPanel: this.initialPanel,
            databaseList: this.dbList,
            // databaseListData: '',
            showAutoCodeAlert: false,
            showUploadAlert: false,
            loadingOverlayActive: false
        }
    }

    // sets initial table for local storage instances
    setInitialPanel = () => {
        if (localStorage.getItem('panelNum') !== null) {
            return localStorage.getItem('panelNum');
        }
        else {
            return null;
        }
    }

    // sets current panel clicked depending on Panel.js response
    handleCurrentPanelClick = (panelNum) => {

        this.setState({
            currentPanel: panelNum,
        }, () => {
            this.getDatabaseListData()
        })
    }

    // get user permissions
    getPermissions = () => {
        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(this.user)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + '/backend/venv/database/admin/userPermissions', requestData)
                .then(response => response.json())
                .then(data => {
                    this.setState({ userPermissions: data })
                });

        } catch (error) {
            console.log(error);
        }
    }

    // get the database list, POST user data to backend API to get which databases they can access
    getDatabaseList = () => {
        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(this.user)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + '/backend/venv/database/admin/dbList', requestData)
                .then(response => response.json())
                .then(data => {
                    // only fetch if a panel is currently clicked
                    this.setState({ databaseList: data },
                        (this.state.currentPanel != null) ? this.getDatabaseListData : null);
                });

        } catch (error) {
            console.log(error);
        }
    }

    // get the database list data, POST the users database list (for current clicked database) to backend API to get database data
    getDatabaseListData = () => {

        this.setState({
            loadingOverlayActive: true,
        }, () => {})

        // creating body for POST
        let body = {
            user: this.user,
            databases: this.state.databaseList[this.state.currentPanel]
        }

        try {
            // creating POST request
            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            };

            // fetching data and waiting until response is resolved before setting state
            fetch(backendPath + '/backend/venv/database/admin/dbListData', requestData)
                .then(res => res.json())
                .then(data => {
                    this.setState({ databaseListData: data },
                        () => {
                            this.setState({
                                loadingOverlayActive: false,}, () => {})
                        });
                });

        } catch (error) {
            console.log(error);
        }
    }

    // handles preparing and showing the auto coding alert during executing the job
    handleAutoCodeAlert = (show, progress) => {
        this.setState({ showAutoCodeAlert: show }, () => {
            // finished alert
            if (progress === 'finished') {
                // set alert
                const element =
                    <Alert variant="primary">
                        <Alert.Heading>Auto Coding In Process</Alert.Heading>
                        <p style={{ fontSize: "14px" }}>*Refresh to see the auto coding database being updated in real-time.</p>
                    </Alert>;
                ReactDOM.render(element, document.getElementById('showAutoCodeAlert'))

                // styling
                document.getElementById("showAutoCodeAlert").style.marginBottom = "-25px";

                // remove alert after the 30 second timer is finished
                window.setTimeout(() => {
                    ReactDOM.render(<></>, document.getElementById('showAutoCodeAlert'))

                    // styling
                    document.getElementById("showAutoCodeAlert").style.marginBottom = "0";
                }, 30000)

            }
            // cancelled alert
            else if (progress === 'cancelled') {
                // set alert
                const element =
                    <Alert variant="danger">
                        <Alert.Heading>Cancelled Auto Coding Job.</Alert.Heading>
                    </Alert>;
                ReactDOM.render(element, document.getElementById('showAutoCodeAlert'))

                // styling
                document.getElementById("showAutoCodeAlert").style.marginBottom = "-25px";

                // remove alert after the 30 second timer is finished
                window.setTimeout(() => {
                    ReactDOM.render(<></>, document.getElementById('showAutoCodeAlert'))

                    // styling
                    document.getElementById("showAutoCodeAlert").style.marginBottom = "0";
                }, 30000)
            }
            // remove alert
            else {
                ReactDOM.render(<></>, document.getElementById('showAutoCodeAlert'))

                // styling
                document.getElementById("showAutoCodeAlert").style.marginBottom = "0";
            }
        })
    }

    // handles preparing and showing the upload and email alert during executing the job
    handleUploadAlert = (show, progress) => {
        this.setState({ showUploadAlert: show }, () => {

            // finished alert
            if (progress === 'finished') {
                // set alert
                const element =
                    <Alert variant="primary">
                        <Alert.Heading>Email/Upload Job In Process</Alert.Heading>
                        <p style={{ fontSize: "14px" }}>*Refresh to see database in list. For emails and upload, depending on the size of the database it may take a while to send all of them.</p>
                    </Alert>;
                ReactDOM.render(element, document.getElementById('showUploadAlert'))

                // styling
                document.getElementById("showUploadAlert").style.marginBottom = "-25px";

                // remove alert after the 30 second timer is finished
                window.setTimeout(() => {
                    ReactDOM.render(<></>, document.getElementById('showUploadAlert'))

                    // styling
                    document.getElementById("showUploadAlert").style.marginBottom = "0";
                }, 30000)
            }
            // cancelled alert
            else if (progress === 'cancelled') {
                // set alert
                const element =
                    <Alert variant="danger">
                        <Alert.Heading>Cancelled Email/Upload Job.</Alert.Heading>
                    </Alert>;
                ReactDOM.render(element, document.getElementById('showUploadAlert'))

                // styling
                document.getElementById("showUploadAlert").style.marginBottom = "-25px";

                // remove alert after the 30 second timer is finished
                window.setTimeout(() => {
                    ReactDOM.render(<></>, document.getElementById('showUploadAlert'))

                    // styling
                    document.getElementById("showUploadAlert").style.marginBottom = "0";
                }, 30000)
            }
            // remove alert
            else {
                ReactDOM.render(<></>, document.getElementById('showUploadAlert'))

                // styling
                document.getElementById("showUploadAlert").style.marginBottom = "0";
            }
        })
    }

    // render
    render() {

        // since components render before GET request for DB data, return loading until GET is complete. 
        // if (typeof this.state.databaseList === "undefined" || typeof this.state.databaseListData === "undefined") {
        // return (<LoadingPage />)
        // }
        if (typeof this.state.userPermissions === "undefined" || typeof this.state.databaseList === "undefined") {
            return (<LoadingPage />)
        }
        // for cases where user is set in auth0 but not in backend API
        else if (typeof this.state.databaseList === 'string') {
            return (
                <div className="Dashboard">
                    <br /><b>{this.state.databaseList}.</b>
                    <br />A system administrator still needs to set up your account.
                    <Auth0LogoutButton />
                </div>
            )
        }
        // (extreme case) since components are defined, make sure that the current panel doesn't point to a deleted database
        else {
            // panel is pointing to a non-existent db, erase the local storage and refresh
            if (this.state.currentPanel >= this.state.databaseList.length) {

                // clear local storage because value is bad
                localStorage.removeItem('panelNum')

                // reload
                window.location.reload();
            }
        }

        // return correct components for current panel selection
        return (

            <div className="Dashboard">

                <ReturnButton />

                <Profile />

                <div className="buttonDiv">
                    <RefreshButton />
                    <Auth0LogoutButton />
                    {typeof this.state.databaseListData === "undefined" ?
                        <ExportButton filename={this.state.databaseList[this.state.currentPanel]} tableData={''} />
                        :
                        // <ExportButton filename={this.state.databaseList[this.state.currentPanel]} tableData={this.state.databaseListData[this.state.currentPanel]} />
                        <ExportButton filename={this.state.databaseList[this.state.currentPanel]} tableData={this.state.databaseListData[0]} />
                    }
                    <UploadAndEmailButton setAlert={this.handleUploadAlert} enabled={this.state.userPermissions["email_and_upload"]} dbLimitValues={{"current" : this.state.databaseList.length - 1, "limit" : this.state.userPermissions["database_limit"]}}/>     {/* current size is - 1 due to example database that we provide */}
                    <AutoCodeButton setAlert={this.handleAutoCodeAlert} enabled={this.state.userPermissions["autocode"]} dbLimitValues={{"current" : this.state.databaseList.length - 1, "limit" : this.state.userPermissions["database_limit"]}}/>                 {/* current size is - 1 due to example database that we provide */}
                    <DeleteButton />
                </div>

                {/* holds alerts for auto coding button and upload button*/}
                <div id="showAutoCodeAlert"></div>
                <div id="showUploadAlert"></div>

                <Panels dataFromParent={this.state.databaseList} parentCallback={this.handleCurrentPanelClick} loading={this.state.loadingOverlayActive}/>

                {/* renders header depending on if a panel is clicked */}
                {this.state.currentPanel === null ?
                    <h2>Click a panel in the database list to get started.</h2>
                    :
                    <h2><b>Current:</b> {getFileName(this.state.databaseList[this.state.currentPanel])}</h2>
                }

                {/* handles table rendering depending on if something is clicked or not */}
                {this.state.currentPanel === null ?
                    <span>Waiting for selection...</span>
                    :
                    <>
                        {/* main table component */}
                        <RenderTable data={(typeof this.state.databaseListData === "undefined") ? 'no_data' : this.state.databaseListData[0]} loading={this.state.loadingOverlayActive} />

                        {/* disclaimer for dbs */}
                        <div style={{ fontSize: '14px', margin: '0px 40px 10px 50px' }}>*For databases of larger sizes, rows will be limited to 10000. To see the full output, press the export button to download and view locally.</div>

                        {/* whitespace filler */}
                        <div className="fillerAdmin"></div>
                    </>
                }
            </div>
        );
    }
}
export default Dashboard;
