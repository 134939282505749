import Role from "./Role";

export const hasRole = (user) => {
    return getRole(user)
}

export const canAccessAdminPage = (user) => {
    let role = getRole(user)
    return role && (role === Role.Admin || role === Role.Xerox_Admin || role === Role.Partner)
}

const getRole = (user) => {
    return user["http://autonoc/roles"].toString();
}