import React from "react"
import Header from '../components/matrix-page/Header'
import CompareUi from '../components/matrix-page/compare/CompareUi'
import '../styles/ComparePage.scss';

const ComparePage = () => {

    return (
        <div className="comparePage">
            <Header />
            <CompareUi />
        </div>
    )
}
export default ComparePage