/*
    By: Jordan Carter
    Description: Hence the filename, a class to store commonly used functions throughout the app.
*/

export const getBackendPath = () => {
    if (window.location.protocol === 'https:')
        return process.env.REACT_APP_BACKEND_PATH_SECURE
    else
        return process.env.REACT_APP_BACKEND_PATH
}

export const localhostCheck = () => {
    let url = window.location.href
    return (url.includes("localhost") || url.includes("127.0.0.1"))
}

// checks URL for xerox var
export const checkUrlForXerox = () => {
    let url = window.location.href;
    return (url.includes("/xerox/") || url.includes("xeroxDeviceUrl="))
}

// finding all the occurrences of an element and returning their indexes
export const findAllIndexesOfChar = (str, char) => {
    const indices = [];
    const array = str.split("");
    let index = array.indexOf(char);
    while (index !== -1) {
      indices.push(index);
      index = array.indexOf(char, index + 1);
    }
    return indices
}

export const getFileName = (path) => {
    if (!path) {
        return null
    }
    else {
        return path.replace(/^.*[\\/]/, '')
    }
}

// formats integrated database attributes to be more readable.
export const formatAttribute = (attribute) => {
    let res = (attribute.startsWith("ch_")) ? "(CH) " : "(O*NET) "
    let type = (res.includes("CH")) ? "CH" : "ONET"

    if (attribute.includes("env_")) {
        res += "Environmental "
    }
    else if (attribute.includes("phys_")) {
        res += "Physical "
    }
    else if (attribute.includes("apt_")) {
        res += "Aptitude "
    }
    else if (attribute.includes("employment_")) { // specific case
        res += "Employment "
    }
    else if (attribute.includes("misc_")) {
        res += "Miscellaneous "
    }
    else if (attribute.includes("psych_")) {
        res += "Psychological "
    }
    
    let indices = findAllIndexesOfChar(attribute, "_")
    let underScorePos = (attribute.startsWith("ch_")) ? indices[1] : indices[2]
    res += attribute.substring(underScorePos + 1, attribute.length)

    if (res.includes("_")) {
        res = res.replaceAll("_", " ")
    }

    // skip first word, capitalize the rest
    let words = res.split(" ");
    for (let i = 1; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }

    res = words.join(" ")

    // remove section name
    let replaceList = ["Physical", "Aptitude", "Miscellaneous", "Psychological", "Environmental"]
    for (let i = 0 ; i < replaceList.length ; i++) {
        if (type == "CH")
            res = res.replace(replaceList[i], "")
        else {
            if (replaceList[i] == "Environmental" || replaceList[i] == "Psychological") {
                res = res.replace(replaceList[i], "")
            }
        }
    }

    res = res.trim()
    return res
}

// formats the Canadian Career Handbook attribute values into a more numerical representation
export const formatScore = (type, score) => {
    let s = score
    if (!(s instanceof String)) 
        s = s.toString()
    s = s.trim()

    if (type.toUpperCase() == "CH") {
        s = s.replace('-', '=') // TODO is this still needed?

        if (s.startsWith("L"))
            s = s.replace("L", "")
        else if (s.startsWith("H"))
            s = s.replace("H", "")
        else if (s.startsWith("D"))
            s = s.replace("D", "")
        else if (s.startsWith("+"))
            s = s.replace("+", "9 (represents \'+\')")
        else if (s.startsWith("R"))
            s = s.replace("R", "0 (represents \'R\')")
    }

    return s
}  

// Some columns need to be adjusted as the integrated database variables differ from the actual variables, essentially just a hardcoded map so we grab the correct scoring
export const overrideAttributeNameForScoring = (type, cellName) => {
    let newCellName = cellName
    if (type == "CH") {
        if (cellName.includes("coordination")) {
            newCellName = cellName.replace("coordination", "co-ordination")
        }
    }
    else {
        if (cellName.includes("environmental indoors controlled")) {
            newCellName = cellName.replace("environmental indoors controlled", "indoors, environmentally controlled")
        }
        else if (cellName.includes("indoors controlled")) {
            newCellName = cellName.replace("indoors controlled", "indoors, environmentally controlled")
        }
        else if (cellName.includes("environmental indoors not controlled")) {
            newCellName = cellName.replace("environmental indoors not controlled", "indoors, not environmentally controlled")
        }
        else if (cellName.includes("indoors not controlled")) {
            newCellName = cellName.replace("indoors not controlled", "indoors, not environmentally controlled")
        }
        else if (cellName.includes("environmental outdoors exposed to weather")) {
            newCellName = cellName.replace("environmental outdoors exposed to weather", "outdoors, exposed to weather")
        }
        else if (cellName.includes("outdoors exposed to weather")) {
            newCellName = cellName.replace("outdoors exposed to weather", "outdoors, exposed to weather")
        }
        else if (cellName.includes("environmental in enclosed vehicle")) {
            newCellName = cellName.replace("environmental in enclosed vehicle", "in an enclosed vehicle or equipment")
        }
        else if (cellName.includes("in enclosed vehicle")) {
            newCellName = cellName.replace("in enclosed vehicle", "in an enclosed vehicle or equipment")
        }
        else if (cellName.includes("environmental exposed to desease infections")) {
            newCellName = cellName.replace("environmental exposed to desease infections", "exposed to disease or infections")
        }
        else if (cellName.includes("exposed to desease infections")) {
            newCellName = cellName.replace("exposed to desease infections", "exposed to disease or infections")
        }
        else if (cellName.includes("environmental spend time climbing")) {
            newCellName = cellName.replace("environmental spend time climbing", "spend time climbing ladders, scaffolds, or poles")
        }
        else if (cellName.includes("spend time climbing")) {
            newCellName = cellName.replace("spend time climbing", "spend time climbing ladders, scaffolds, or poles")
        }
        else if (cellName.includes("environmental uncomfortable sounds")) {
            newCellName = cellName.replace("environmental uncomfortable sounds", "sounds, noise levels are distracting or uncomfortable")
        }
        else if (cellName.includes("uncomfortable sounds")) {
            newCellName = cellName.replace("uncomfortable sounds", "sounds, noise levels are distracting or uncomfortable")
        }
        else if (cellName.includes("environmental exposed to body vibration")) {
            newCellName = cellName.replace("environmental exposed to body vibration", "exposed to whole body vibration")
        }
        else if (cellName.includes("exposed to body vibration")) {
            newCellName = cellName.replace("exposed to body vibration", "exposed to whole body vibration")
        }
        else if (cellName.includes("environmental face to face")) {
            newCellName = cellName.replace("environmental face to face", "face-to-face discussions")
        }
        else if (cellName.includes("face to face")) {
            newCellName = cellName.replace("face to face", "face-to-face discussions")
        }
        else if (cellName.includes("color discrimination")) {
            newCellName = cellName.replace("color discrimination", "visual color discrimination")
        }
        else if (cellName.includes("spend time kneeling crouching stooping crawling")) {
            newCellName = cellName.replace("spend time kneeling crouching stooping crawling", "spend time kneeling, crouching, stooping, or crawling")
        }
        else if (cellName.includes("spend time bending twisting body")) {
            newCellName = cellName.replace("spend time bending twisting body", "spend time bending or twisting the body")
        }
        else if (cellName.includes("spend time using hands")) {
            newCellName = cellName.replace("spend time using hands", "spend time using your hands to handle, control, or feel objects, tools, or controls")
        }
        else if (cellName.includes("spend time making repetitve motions")) {
            newCellName = cellName.replace("spend time making repetitve motions", "spend time making repetitive motions")
        }
        else if (cellName.includes("cramped space awkward positions")) {
            newCellName = cellName.replace("cramped space awkward positions", "cramped work space, awkward positions")
        }
        else if (cellName.includes("arm hand steadiness")) {
            newCellName = cellName.replace("arm hand steadiness", "arm-hand steadiness")
        }
        else if (cellName.includes("handling moving objects")) {
            newCellName = cellName.replace("handling moving objects", "handling and moving objects")
        }
        else if (cellName.includes("wrist finger speed")) {
            newCellName = cellName.replace("wrist finger speed", "wrist-finger speed")
        }
        else if (cellName.includes("psychological hours per week")) {
            newCellName = cellName.replace("psychological hours per week", "duration of typical work week")
        }
        else if (cellName.includes("hours per week")) {
            newCellName = cellName.replace("hours per week", "duration of typical work week")
        }
        else if (cellName.includes("psychological decision freedom")) {
            newCellName = cellName.replace("psychological decision freedom", "freedom to make decisions")
        }
        else if (cellName.includes("decision freedom")) {
            newCellName = cellName.replace("decision freedom", "freedom to make decisions")
        }
        else if (cellName.includes("psychological decision frequency")) {
            newCellName = cellName.replace("psychological decision frequency", "frequency of decision making")
        }
        else if (cellName.includes("decision frequency")) {
            newCellName = cellName.replace("decision frequency", "frequency of decision making")
        }
        else if (cellName.includes("psychological decision impact")) {
            newCellName = cellName.replace("psychological decision impact", "impact of decisions on co-workers or company results")
        }
        else if (cellName.includes("decision impact")) {
            newCellName = cellName.replace("decision impact", "impact of decisions on co-workers or company results")
        }
        else if (cellName.includes("psychological accuracy importance")) {
            newCellName = cellName.replace("psychological accuracy importance", "importance of being exact or accurate")
        }
        else if (cellName.includes("accuracy importance")) {
            newCellName = cellName.replace("accuracy importance", "importance of being exact or accurate")
        }
        else if (cellName.includes("psychological repetition importance")) {
            newCellName = cellName.replace("psychological repetition importance", "importance of repeating same tasks")
        }
        else if (cellName.includes("repetition importance")) {
            newCellName = cellName.replace("repetition importance", "importance of repeating same tasks")
        }
        else if (cellName.includes("psychological competition")) {
            newCellName = cellName.replace("psychological competition", "level of competition")
        }
        else if (cellName.includes("competition")) {
            newCellName = cellName.replace("competition", "level of competition")
        }
        else if (cellName.includes("psychological equipment pace")) {
            newCellName = cellName.replace("psychological equipment pace", "pace determined by speed of equipment")
        }
        else if (cellName.includes("equipment pace")) {
            newCellName = cellName.replace("equipment pace", "pace determined by speed of equipment")
        }
        else if (cellName.includes("psychological structured vs unstructured")) {
            newCellName = cellName.replace("psychological structured vs unstructured", "structured versus unstructured work")
        }
        else if (cellName.includes("structured vs unstructured")) {
            newCellName = cellName.replace("structured vs unstructured", "structured versus unstructured work")
        }
        else if (cellName.includes("psychological strict deadlines")) {
            newCellName = cellName.replace("psychological strict deadlines", "time pressure")
        }
        else if (cellName.includes("strict deadlines")) {
            newCellName = cellName.replace("strict deadlines", "time pressure")
        }
        else if (cellName.includes("psychological stress index")) {
            newCellName = cellName.replace("psychological stress index", "stress tolerance")
        }
        else if (cellName.includes("stress index")) {
            newCellName = cellName.replace("stress index", "stress tolerance")
        }
    }
    
    return newCellName
}

