import React from 'react';
import '../../styles/ResultPage.scss';

const JobListPopup = ({ handleClose, show, jobData }) => {
    const showHideClassName = show ? "jobPopup display-block" : "jobPopup display-none";

    // formatting job list for readability and rendering as html list.
    const formatJobs = (jobs) => {
        // taking JSON object and splitting it up into an array
        var myString = JSON.stringify(jobs);
        var myArray = myString.split(';');

        // check each string element and remove special characters
        for (var i = 0; i < myArray.length; i++) {
            // remove " and /
            myArray[i] = myArray[i].replace("\"", "");
        }

        // remove element from list if empty string
        myArray = myArray.filter(function (str) {
            return /\S/.test(str);
        });

        // return as list
        return (
            myArray.map(
                function (job) {
                    return <li key={job}>{job}</li>;
                }
            )
        )
    }

    // return components
    return (
        <div className={showHideClassName}>
            <div className="jobPopupDiv">
                <h1>Full List of Job Titles:</h1>

                <div className="list">{formatJobs(jobData)}</div>
                
                <button type="button" onClick={handleClose}>Close</button>
            </div>
        </div>
    );
};

export default JobListPopup;