import React from "react";
import { useParams } from 'react-router-dom';
import logo from '../../assets/AUTONOC-logo.png'
import XeroxHomeButton from "../xerox/XeroxHomeButton";
import XeroxBackButton from "../xerox/XeroxBackButton";
import { checkUrlForXerox } from "../../HelperFunctions";

const Header = () => {

    // grabbing xerox device url from params 
    const { xeroxDeviceUrl } = useParams();

    return (
        <div className="header">

            {/* (special case) users on Xerox devices need a back button due to no URL on MFDs */}
            {checkUrlForXerox() ? 
                <div id="xeroxButtonContainer" style={{marginTop: "15px"}}>
                    <XeroxHomeButton xeroxDeviceUrl={xeroxDeviceUrl}/>
                    <XeroxBackButton /> 
                </div>
                : 
                <></>
            }

            <img className= "logo" src={logo} alt="sample logo for AUTONOC" />
        </div>
    )
}

export default Header