import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table-next'
import { Spinner } from "react-bootstrap"
import { useParams, Link } from "react-router-dom";
import { checkUrlForXerox } from "../../HelperFunctions";

const RenderMatrixTable = (props) => {

    // grabbing xerox device url from params 
    const { xeroxDeviceUrl } = useParams();

    // fetching data from backend
    if (props.data === 'no_data') {
        return (
            <div id="spinnerDiv" style={{marginTop: '40px', marginBottom: '40px'}}>
                <Spinner id="theSpinner" animation="border" role="status" style={{ width: '5rem', height: '5rem' }}></Spinner>
                <div id="spinnerText" style={{marginTop: '0', textAlign: 'center' }}>Fetching Data...</div>
            </div>
        )
    }

    // returns column headers + can format info in row where necessary. 
    const getColumnHeaders = () => {

        // grabs headers (keys from dict)
        var keys = Object.keys(rowData[0]);

        // format headers into --> [{dataField: keys[i], text: keys[i]}, {...}]
        var result = []
        for (var i = 0; i < keys.length; i++) {
            if (keys[i].toLowerCase() == "noc_code") {
                result[i] = { 
                    dataField: keys[i],
                    text: "NOC Code",
                    formatter: (cell, row) => <div dangerouslySetInnerHTML={{ __html: formatNocCodesAndLinks(cell) }}></div>
                }
            }
            else if (keys[i].toLowerCase() == "noc_title") {
                result[i] = { 
                    dataField: keys[i],
                    text: "Job Title (NOC)",
                }
            }
            else if (keys[i].toLowerCase() == "soc_code(s)") {
                result[i] = { 
                    dataField: keys[i], 
                    text: "SOC Code",
                    formatter: (cell, row) => <div dangerouslySetInnerHTML={{ __html: formatSocCodesAndLinks(cell) }}></div>
                }
            }
            else if (keys[i].toLowerCase() == "soc_title(s)") {
                result[i] = { 
                    dataField: keys[i], 
                    text: "Job Titles (SOC)",
                    style: {
                        textAlign: 'left',
                    },
                    formatter: (cell, row) => <div dangerouslySetInnerHTML={{ __html: formatSocTitles(cell) }}></div>
                }
            }
            else if (keys[i].toLowerCase() == "compare") {
                result[i] = { 
                    dataField: keys[i], 
                    text: keys[i],
                    hidden: true // hiding this column by default, only need it for the formatted cell data
                }
            }
            else if (keys[i].toLowerCase() == "neighbours") {
                result[i] = { 
                    dataField: keys[i], 
                    text: keys[i],
                    formatter:(cell, row) => <Link to={createNeighbourPageLink(cell)} target='_blank'>Neighbours</Link>
                }
            }
            else {
                result[i] = { dataField: keys[i], text: keys[i] }
            }
        }

        // creating dummy header and setting it to the random unique key generated for each row
        let dummy = {
            dataField: 'uniqueKey',
            text: 'uniqueKey',
            isDummyField: true,
            hidden: true,
        }

        // setting random uniqueKey column as a dummy field so it doesnt show when rendered
        result[result.length - 1] = dummy

        return result;
    }

    // helper for noc link column
    const formatNocCodesAndLinks = (cell) => {
        let nocLink = cell.split(',')
        return "<a href=\"" + nocLink[1] + "\" target=\"_blank\">" + nocLink[0] + "</a>"
        // return "<a href=\"https://noc.esdc.gc.ca/CareerHandbook/ChWelcome\" target=\"_blank\">" + nocLink[0] + "</a>"
    }

    // helper for soc link column
    const formatSocCodesAndLinks = (cell) => {
        let socCodes = cell.substring(0, cell.indexOf('|')).split(',')
        let socLinks = cell.substring(cell.indexOf('|') + 1).split(',')
        
        let resultHtml = "<div>"
        for (let i = 0; i < socCodes.length; i++) {
            resultHtml += "" + (i + 1) + ". "
            resultHtml += "<a href=\"" + socLinks[i] + "\" target=\"_blank\">" + socCodes[i] + "</a>"
            resultHtml += "<br>"
        }

        resultHtml += "</div>"
        return resultHtml
    }

    const formatSocTitles = (cell) => {
        let socTitles = cell.split('|')

        let resultHtml = "<div>"
        for (let i = 0; i < socTitles.length; i++) {
            resultHtml += "" + (i + 1) + ". "
            resultHtml += socTitles[i]
            resultHtml += "<br>"
        }

        resultHtml += "</div>"
        return resultHtml
    }

    const createComparePageLink = (cell) => {
        return "/matrix/compare/codes=" + cell + (checkUrlForXerox() ? "&xeroxDeviceUrl=" + xeroxDeviceUrl : "")
    }

    const createNeighbourPageLink = (cell) => {
        return "/matrix/neighbour/codes=" + cell + (checkUrlForXerox() ? "&xeroxDeviceUrl=" + xeroxDeviceUrl : "")
    }

    // passes row data to attach a unique key for each entry (fixes error --> Warning: Each child in an array or iterator should have a unique "key" prop)
    const addUniqueKeyToData = (data) => {
        let theData = data

        for (let i = 0; i < theData.length; i++) {
            theData[i]['uniqueKey'] = i
        }

        return theData;
    }

    // row data with unique key
    const rowData = addUniqueKeyToData(props.data)

    // shows data/total depending on pagination
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );

    // options for pagination
    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        hideSizePerPage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: rowData.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    }

    const options = {
        page: 1,
        custom: true,
        totalSize: rowData.length
    };

    // incase db is broken
    if (props.data.length === 0) {
        return (<div>Warning: No results for entered job title or database is empty/broken.</div>)
    }
    // data still loading
    else if (props.loading === true) {
        return (
            <div className="loadingOverlay">
                <div id="spinnerDiv" style={{marginTop: '40px', marginBottom: '40px'}}>
                    <Spinner id="theSpinner" animation="border" role="status" style={{ width: '5rem', height: '5rem' }}></Spinner>
                    <div id="spinnerText" style={{marginTop: '0', textAlign: 'center' }}>Fetching Data...</div>
                </div>
            </div>
        )
    }
    // render normally
    else {
        return (
            // <PaginationProvider
                // pagination={paginationFactory(options)}
            // >
                // {
                //     ({
                //         paginationProps,
                //         paginationTableProps
                //     }) => (
                        <>
                            <div className="renderMatrixTableDiv" id="renderMatrixTableDivID">
                                <BootstrapTable
                                    keyField='uniqueKey'
                                    data={rowData}
                                    columns={getColumnHeaders()}
                                    striped
                                    hover
                                    condensed
                                    noDataIndication="Table is Empty"
                                    // {...paginationTableProps}
                                    selectRow={{
                                        mode: 'checkbox',
                                        clickToSelect: true,
                                        onSelect: (row, isSelect, rowIndex, e) => {
                                            props.selectionCallback({
                                                mode: 'single',
                                                rowData: row,
                                                isSelect: isSelect,
                                                rowIndex: rowIndex
                                            })
                                        },
                                        onSelectAll: (isSelect, rows, e) => {
                                            props.selectionCallback({
                                                mode: 'all',
                                                rows: rows,
                                                isSelect: isSelect,
                                            })
                                        }
                                    }}
                                />
                            </div>
                            <div className="paginationDiv">
                                <hr />
                                {/* <PaginationTotalStandalone
                                    {...paginationProps}
                                />
                                <PaginationListStandalone
                                    {...paginationProps}
                                /> */}
                            </div>
                        </>
        //             )
        //         }
        //     </PaginationProvider>
        );
    }
}

export default RenderMatrixTable
