import React from "react";
import logo from '../../assets/AUTONOC-logo.png'
import '../../styles/ResultPage.scss';  // copying result pages header due to effectiveness

const HeaderBasic = () => {

    return (
        <div className="header">
            <img className= "logo" src={logo} alt="sample logo for AUTONOC" />
        </div>
    )
}

export default HeaderBasic