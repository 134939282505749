import React from "react"
import '../styles/LandingPage.scss'
import logo from '../assets/AUTONOC-logo.png';
import { useAuth0 } from '@auth0/auth0-react'
import { hasRole } from "../components/auth0/Auth0Util";

const LandingPage = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <div className="landingPage">
            <img src={logo} className="landingPageLogo" alt="autonoc logo"/>
            {isAuthenticated ?
                <p>Welcome!</p>
                :
                <p>Please sign in to proceed.</p>
            }
        </div>
    );
}
export default LandingPage