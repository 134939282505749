import React from 'react'
import {useHistory} from 'react-router-dom';
import '../../../styles/AdminPage.scss';
import logo from "../../../assets/return.png"

const ReturnButton = () => {
    const history = useHistory();

    // returns user to home page
    const returnHome = () => {
        history.push('/home')
        // history.push(document.referrer)
    }

    // return components for return button
    return (
        <div className="returnDiv">
            <button className="return" onClick={returnHome}>
                <img className="returnLogo" src={logo} alt="return logo" />
                Return
            </button>
        </div>
    )
}

export default ReturnButton;

