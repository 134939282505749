import React from 'react'
import '../styles/NotAllowedPage.scss'

const NotAllowedPage = () => {

    return (
        <div className="notAllowedPage">
            Sorry, you are not allowed to access this website until your account is granted a role. Please contact a system admin.
        </div>
    )
}

export default NotAllowedPage;