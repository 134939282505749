import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// renders the users information from auth0
const Profile = () => {
    const { user, isAuthenticated } = useAuth0();

    return (
        // only render if user is logged in
        isAuthenticated && (
            <div className="profile">
                <img src={user.picture} alt={user.name} />
                <p><b>{user["http://autonoc/roles"].toString()}</b>{" - " + user.nickname}</p>
            </div>
        )
    )
}

export default Profile;