import React from 'react'
import { withRouter } from 'react-router-dom'
import logo from '../../assets/automate.png'

const MatrixButton = (props) => {

    const routeToMatrix = () => { 
        let deviceUrl = props.xeroxDeviceUrl
        if (deviceUrl === undefined || deviceUrl === "" || deviceUrl === null || deviceUrl === "undefined") {
	        props.history.push({
                pathname: '/matrix/codes=' + props.codes.join(",")
            })
        }
        else {
            props.history.push({
                    pathname: '/matrix/codes=' + props.codes.join(",") + '&xeroxDeviceUrl=' + props.xeroxDeviceUrl
            })
        }
    }

    return (
        <div className="matrixButtonDiv">
            <button className="matrixButton" onClick={routeToMatrix} style={{left: '0', marginTop: '0', border: '1px solid white', width: '125px'}}>
                <img className="image" src={logo} alt="matrix button logo" style={{marginRight: '10px', marginBottom: '2px'}}/>
                Matrix
            </button> 
        </div>
    )
}

export default withRouter(MatrixButton)