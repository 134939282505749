import React, { useState } from 'react'
import logo from "../../../assets/automate.png"
import autocodeExample from "../../../assets/autocodeExample.PNG"
import { useAuth0 } from '@auth0/auth0-react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

// vars
var backendPath = ''
if (window.location.protocol === 'https:')
    backendPath = process.env.REACT_APP_BACKEND_PATH_SECURE
else 
    backendPath = process.env.REACT_APP_BACKEND_PATH

const AutoCodeButton = (props) => {

    // states
    const [clicked, setClicked] = useState(false);
    const [currentFile, setCurrentFile] = useState('');
    const [currentFileRename, setCurrentFileRename] = useState('');
    const [showInstructions, setShowInstructions] = useState(false);
    const [showFilePrompt, setShowFilePrompt] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    // handle closing/showing of modals
    const handleShowInstructions = () => {
        if (props.dbLimitValues["current"] > props.dbLimitValues["limit"]) {
            window.alert("You have exceeded your database limit of " + props.dbLimitValues["limit"] + "!\nYou can't autocode another one until you delete one of the exisiting ones.")
            return
        }
        setShowInstructions(true);
    }
    const handleShowFilePrompt = () => setShowFilePrompt(true);
    const handleShowErrorPopup = () => setShowErrorPopup(true);
    const handleCloseInstructions = () => setShowInstructions(false);
    const handleCloseFilePrompt = () => setShowFilePrompt(false);
    const handleCloseErrorPopup = () => setShowErrorPopup(false);

    // getting auth0 user to send as body for POST
    const { user } = useAuth0()

    // on click function, starts the auto coding process
    const startProcess = () => {

        // only allow one process at a time
        if (clicked === false) {

            // hide file popup
            handleCloseFilePrompt()

            // no file selected
            if (currentFile === '') {
                setTimeout(function () {
                    displayErrorPopup('error_nofile')
                }, 1000);
                return
            }

            // set state to true because it's starting
            setClicked(true)

            // show popup alert
            props.setAlert(true)

            // create form
            const formData = new FormData();
            formData.append('user', JSON.stringify(user))
            formData.append('file', currentFile)
            formData.append('fileName', JSON.stringify(currentFileRename))

            // POST call to backend triggering it to start
            try {
                // creating POST request
                const requestData = {
                    method: 'POST',
                    body: formData
                };

                // fetching data and waiting until response is resolved before setting state
                fetch(backendPath + '/backend/venv/database/admin/autocode', requestData)
                    .then(response => response.json())
                    .then(data => {
                        // show cancelled alert and display popup with correct error message
                        if (data[0] === 'cancelled') {

                            // show cancelled alert and error popup
                            props.setAlert(false, 'cancelled')

                            // grab error status depending on error code
                            setTimeout(function () {
                                displayErrorPopup(data[1])
                            }, 1000);

                        }
                        // hide popup alert and send that it was finished
                        else {
                            props.setAlert(false, 'finished')
                        }

                        // set state to false to enable auto coding
                        setClicked(false)
                    });

            } catch (error) {
                console.log(error);
            }
        }
        // when user is trying to click the button more than once
        else {
            alert("Please only run one auto coding process.")
            console.log("ERROR: only one auto coding process should be ran.")
        }
    }

    // grab error status, and display the correct error message
    const displayErrorPopup = (errorCode) => {
        handleShowErrorPopup();

        switch (errorCode) {
            case 'error_nofile':
                document.getElementById('errorPopupTitle').innerHTML = "ERROR: No File Selected"
                document.getElementById('errorPopupBody').innerHTML = "You didn't select a file, try again."
                break;
            case 'error_baduser':
                document.getElementById('errorPopupTitle').innerHTML = "ERROR: Bad User"
                document.getElementById('errorPopupBody').innerHTML = "Your account is not registered in our backend, please contact a system administrator to gain access."
                break;
            case 'error_extension':
                document.getElementById('errorPopupTitle').innerHTML = "ERROR: Wrong Extension"
                document.getElementById('errorPopupBody').innerHTML = "Your file has the wrong extension, make sure the database entered is a CSV file (.csv)."
                break;
            case 'error_rename':
                document.getElementById('errorPopupTitle').innerHTML = "ERROR: File Already Exists"
                document.getElementById('errorPopupBody').innerHTML = "Please enter an alternative name.<br/>There is a csv file on the server with the exact same name as the user provided csv."
                break;
            case 'error_dbvalidation':
                document.getElementById('errorPopupTitle').innerHTML = "ERROR: Failed Validation Check"
                document.getElementById('errorPopupBody').innerHTML = "Your database failed the validation check, please review your database before trying again.<br/><br/>It's missing the following column:<ul><li><u>uid</u> or <u>id</u> (optional, but recommended)</li><li><u>jobTitle</u> or <u>job_title</u></li></ul>"
                break;
            case 'error_alreadyinprogress':
                document.getElementById('errorPopupTitle').innerHTML = "ERROR: Already Running Script"
                document.getElementById('errorPopupBody').innerHTML = "Please wait until your other Autocode script is finished before starting a new one!"
                break;
            default:
                document.getElementById('errorPopupTitle').innerHTML = "ERROR"
                document.getElementById('errorPopupBody').innerHTML = "An unaccounted error has occured, please contact a system administrator."
                break;
        }
    }

    return (
        <>
            {props.enabled == true ?
                <div className="autocodeDiv">
                    <button className="autocode" onClick={handleShowInstructions}>
                        <img className="autocodeLogo" src={logo} alt="autocode logo" />
                        Auto Code
                    </button>
                </div>
                :
                <div className="autocodeDiv" style={{opacity: "0.7"}}>
                    <button className="autocode" onClick={handleShowInstructions} disabled={true}>
                        <img className="autocodeLogo" src={logo} alt="autocode logo" />
                        Auto Code
                    </button>
                </div>
            }

            {/* popup showing instructions */}
            <Modal
                show={showInstructions}
                onHide={handleCloseInstructions}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Auto Coding Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Follow these instructions to automatically code a database through the AUTONOC pipeline:
                    <ol>
                        <li>Upload a CSV file that contains the following column:
                            <ul>
                                <li>
                                    <u>jobTitle</u>, <u>jobTitles</u>, <u>job_title</u>, or <u>job_titles</u>.
                                </li>
                                <li>
                                    <u>unique_id</u>, <u>uid</u>, or <u>id</u>. (Recommended)
                                </li>
                            </ul>
                            <div style={{ fontSize: '14px' }}>*Database can contain any # of columns, server will only look at the ones listed above.</div>
                        </li>
                        <li>Backend server will read the job titles from the database and put each job through the AUTONOC pipeline.</li>
                        <li>Once finished the resulting database will be saved onto the server for your viewing.</li>
                    </ol>
                    Example:
                    <br />
                    <img src={autocodeExample} alt="example of a correct database for auto coding process" />
                    <br /><br />
                    <b>Do you still wish to proceed?</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInstructions} style={{ height: 'auto' }}>Cancel</Button>
                    <Button variant="primary" onClick={() => { handleCloseInstructions(); handleShowFilePrompt() }} style={{ height: 'auto' }}>Continue</Button>
                </Modal.Footer>
            </Modal>

            {/* popup asking for a file prompt */}
            <Modal
                show={showFilePrompt}
                onHide={handleCloseFilePrompt}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please Enter a Database</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="file" accept=".csv" onChange={event => setCurrentFile(event.target.files[0])}></input>
                    <br /><br />
                    Do you wish to rename this file? Leave empty if not.
                    <br />
                    <input type="text" onChange={event => setCurrentFileRename(event.target.value + '.csv')} style={{width: '100%'}}></input>
                    <br /><br />
                    <div><b>Note:</b> Only CSV file extensions (.csv) will work.</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilePrompt} style={{ height: 'auto' }}>Cancel</Button>
                    <Button variant="primary" onClick={startProcess} style={{ height: 'auto' }}>Continue</Button>
                </Modal.Footer>
            </Modal>

            {/* error modal popup*/}
            <Modal
                show={showErrorPopup}
                onHide={handleCloseErrorPopup}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="errorPopupTitle">ERROR</Modal.Title>
                </Modal.Header>
                <Modal.Body id="errorPopupBody">
                    Error Body.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseErrorPopup} style={{ height: 'auto' }}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AutoCodeButton
